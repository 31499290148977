import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

import { googleLogout, GoogleLogin } from "@react-oauth/google";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { jwtDecode } from "jwt-decode";
import { REACT_APP_API_TRUEUP } from "../config";
import RestfulAPICall from "../utility/restfulapicall.js";
import { useProfileContext } from "./Layout";

function Header() {
  const location = useLocation();
  const [hidden, setHidden] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [user, setUser] = useState([]);
  // const [user, setUser] = useState([]);
  const { googleProfile, setGoogleProfile, sessionContext, setUserSession, clearSessionContext } =
    useProfileContext();
  const [merchantId, setMerchantId] = useState(sessionContext && sessionContext.merchantId);
  // const { setUserSession } = useExecutionContext();

  // const {token, setToken} = useToken();

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => { setUser(codeResponse); console.log('Login Success:', codeResponse) },
  //   onError: (error) => console.log('Login Failed:', error)
  // });

  const handleGoogleLogin = async (googleData) => {
    // console.log('GoogleData:', googleData.credential);
    var details = jwtDecode(googleData.credential);
    // console.log('details:', JSON.stringify(details));
    // console.log(JSON.stringify(details));
    // console.log(googleData);

    // var objUser = jwtDecode(googleData.credentials);
    // console.log('objUser:', JSON.stringify(objUser));

    // setUser(details);
    details.id_token = googleData.credential;
    setGoogleProfile(details);

    // console.log('Google login initiated');
    // console.log(googleData);

    // Call the api login if the user is not in the database
    handleClose();

    console.log(`Header.js::handleGoogleLogin: Bearer = ${googleData.credential}`);
    const users_exists_url = `${REACT_APP_API_TRUEUP}users/exists`;
    console.log(`Header.js::handleGoogleLogin: users_exists_url = ${users_exists_url}`);
    RestfulAPICall(users_exists_url, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${googleData.credential}`,
      },
      body: "",
    })
      .then((response) => {
        console.log(`Header.js::handleGoogleLogin: Response = ${JSON.stringify(response)}`);

        if (response.data.user_exists) {
          const { role } = response.data.user;
          const merchantIdExists = response.data?.merchant_id_exists;

          console.log(`Header.js::handleGoogleLogin: role = ${role}`);
          console.log(`Header.js::handleGoogleLogin: merchantIdExists = ${merchantIdExists}`);

          if (merchantIdExists) {
            const merchantId = response.data?.merchant_id;
            setMerchantId(merchantId);

            if (role === "ADMIN" || role === "OPERATOR") {
              // Continue with normal flow
              setUserSession(googleProfile, merchantId, false);
            } else if (role === "SUPERADMIN") {
              // Redirect to Superadmin Dashboard
              setUserSession(googleProfile, merchantId, role === "SUPERADMIN");

              window.location.href = "/superadmin-login-as-merchant";
            }
          } else {
            // Redirect to the same URL for registering a merchant
            window.location.href = "/register"; // Use the same URL for both
          }
        } else {
          // User does not exist, redirect to the same URL for registering a merchant
          window.location.href = "/register"; // Use the same URL for both
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    // if (googleProfile) {
    console.log(location.pathname + location.search);
    if (location.pathname + location.search === "/") {
      setHidden(true);
    } else {
      setHidden(false);
    }
    // }
  }, [googleProfile]);

  // useEffect(
  //   () => {
  //     if (user && typeof user.access_token !== 'undefined') {
  //       console.log(user.access_token);
  //       axios
  //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: 'application/json'
  //           }
  //         })
  //         .then((res) => {
  //           // setProfile(res.data);
  //           console.log('GoogleProfile:' + JSON.stringify(res.data));
  //           setGoogleProfile(res.data)
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   },
  //   [user]
  // );

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    // setProfile(null);
    setGoogleProfile(null);
    clearSessionContext();
  };

  const [merchantName, setMerchantName] = useState("");

  useEffect(() => {
    if (merchantId) {
      fetchMerchantInfo(merchantId);
    }
  }, [merchantId]);

  const fetchMerchantInfo = async (merchantId) => {
    console.log("fetchMerchantInfo", merchantId);
    try {
      const response = await RestfulAPICall(`${REACT_APP_API_TRUEUP}merchants/${merchantId}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${googleProfile?.id_token}`,
        },
      });
      if (response.data && response.data.merchant_info) {
        setMerchantName(response.data.merchant_info.name);
      }
    } catch (error) {
      console.error("Error fetching merchant info:", error);
    }
  };

  return (
    <>
      <Navbar
        hidden={hidden}
        fixed="top"
        expand="lg"
        className={
          sessionContext && sessionContext.isSuperadminActing ? "bg-warning" : "bg-body-tertiary"
        }
      >
        <Container>
          <Navbar.Brand href="#home" className="d-flex align-items-center">
            <Image src="/trueup.jpg" width={100} />
            {merchantName && (
              <span className="ms-2 text-muted" style={{ fontSize: "0.9rem" }}>
                {merchantName}
              </span>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Home</Nav.Link>
              <Nav.Link href="/campaign">
                <b>Set Up Campaign</b>
              </Nav.Link>
              <Nav.Link href="/customerlist">
                <b>Chat</b>
              </Nav.Link>
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item href="/customersmanagement">
                  <b>Managing Customers</b>
                </NavDropdown.Item>
                <NavDropdown.Item href="/usermanagement">
                  <b>Managing Users</b>
                </NavDropdown.Item>
                <NavDropdown.Item href={`/merchant/${merchantId}`}>
                  Managing Merchant
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/viewallmessages">View all messages</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse></Navbar.Collapse>
          <Navbar.Brand>
            {googleProfile ? (
              <>
                <NavDropdown title={googleProfile.name}>
                  <NavDropdown.Item href={`/merchant/${merchantId}`}>
                    Managing Merchant
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/customersmanagement">
                    Managing Customers
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/usermanagement">Managing Users</NavDropdown.Item>
                  <NavDropdown.Item href="/subscription">Managing Subscription</NavDropdown.Item>
                  {sessionContext && sessionContext.isSuperadminActing && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/superadmin-login-as-merchant">
                        Change Acting Merchant as Superadmin
                      </NavDropdown.Item>
                    </>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logOut}>Sign Out</NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                {/* <button onClick={login}>Sign in with Google 🚀 </button> */}
                <Button variant="" className="d-flex" onClick={handleShow}>
                  <div>
                    <i className="bi bi-person"></i>
                  </div>
                  <div>Sign in / Register</div>
                </Button>
              </>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Sign up / Register</Modal.Title>
          <X className="ms-auto" onClick={handleClose} style={{ cursor: "pointer" }} />
        </Modal.Header>
        <Modal.Body>
          <p>Login with</p>
          <div>
            <div>
              <GoogleLogin
                useOneTap
                onSuccess={handleGoogleLogin}
                onError={() => console.log("Login Failed")}
              />
            </div>
          </div>
          <hr />
          <p>
            By continuing, you agree to our{" "}
            <a href="/optin/sms-terms-of-service.html" target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/optin/privacy-policy.html" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Header;
