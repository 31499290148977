// const API_TRUEUP = "https://www.laplace-innovations.com/api/"
// const API_SMS = "https://www.summerfang.me/api/"
// const NOTIFICATION_SERVER = "https://www.summerfang.me:443"

const REACT_APP_API_TRUEUP = process.env.REACT_APP_API_TRUEUP;
const REACT_APP_API_TRUEUP_WS = process.env.REACT_APP_API_TRUEUP_WS;
const REACT_APP_API_SMS = process.env.REACT_APP_API_SMS;
const REACT_APP_NOTIFICATION_SERVER = process.env.REACT_APP_NOTIFICATION_SERVER;
const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const REACT_APP_SMS_GATEWAY = process.env.REACT_APP_SMS_GATEWAY;
// const REACT_APP_X_API_KEY = process.env.REACT_APP_X_API_KEY

export {
  REACT_APP_API_TRUEUP,
  REACT_APP_API_TRUEUP_WS,
  REACT_APP_API_SMS,
  REACT_APP_NOTIFICATION_SERVER,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_SMS_GATEWAY,
};
