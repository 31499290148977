// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-bubble {
  max-width: 80%;
  border: none;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.chat-bubble .card-body {
  padding: 10px 15px;
}

.left-bubble {
  float: left;
  background-color: #e5e5ea;
}

.right-bubble {
  float: right;
  background-color: #007aff;
  color: white;
}

@media (min-width: 768px) {
  .left-bubble {
    float: right;
  }
  
  .right-bubble {
    float: left;
  }
}
.chat-window {
  height: 300px;
  overflow-y: auto;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 12px;
}

.message.user {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.message.ai {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/home/Home.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,WAAW;EACb;AACF;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".chat-bubble {\n  max-width: 80%;\n  border: none;\n  border-radius: 20px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);\n}\n\n.chat-bubble .card-body {\n  padding: 10px 15px;\n}\n\n.left-bubble {\n  float: left;\n  background-color: #e5e5ea;\n}\n\n.right-bubble {\n  float: right;\n  background-color: #007aff;\n  color: white;\n}\n\n@media (min-width: 768px) {\n  .left-bubble {\n    float: right;\n  }\n  \n  .right-bubble {\n    float: left;\n  }\n}\n.chat-window {\n  height: 300px;\n  overflow-y: auto;\n}\n\n.chat-messages {\n  display: flex;\n  flex-direction: column;\n}\n\n.message {\n  max-width: 80%;\n  margin-bottom: 10px;\n  padding: 8px 12px;\n  border-radius: 12px;\n}\n\n.message.user {\n  align-self: flex-end;\n  background-color: #007bff;\n  color: white;\n}\n\n.message.ai {\n  align-self: flex-start;\n  background-color: #f0f0f0;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
