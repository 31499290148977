import React from 'react';
import ReactDOM from 'react-dom/client';
// import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import './index.css';
// // import App from './App';
import reportWebVitals from './reportWebVitals';
// import { GoogleOAuthProvider } from '@react-oauth/google';

// import Layout from './home/Layout';
// import ErrorPage from './error-pages';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Home from './home/Home';
// import CustomerList from './CustomerList';
// import ScreenChat from './ScreenChat';
// import ViewAllMessages from './ViewAllMessages';
// import Merchant from './admin/merchant';
// import UserManagement from './admin/usermanagement';
// import Subscription from './admin/subscription';
// import CustomersManagement from './admin/customers';
// import OptInForm from './optin/OptInForm'

// import { loader as customerListLoader } from './CustomerList';
// import { loader as SMSLoader } from './ScreenChat';
// import SMSChat from './SMS/SMSChat';
// import { loader as SMSChatLoader } from './SMS/SMSChat';
// import Signup from './signup/Signup';
// import FloatingDialog from './authentication/FloatingDialog';
// import TestGoogleLogin from './test/Google';
// import Register from './signup/register';

import App from './App';

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//     errorElement: <ErrorPage />,

//     children: [
//       {
//         index: true,
//         element: <Home />
//       },
//       {
//         path: '/customerlist',
//         element: <CustomerList />,
//         loader: customerListLoader
//       },
//       {
//         path: '/screenchat',
//         element: <ScreenChat />,
//         loader: SMSLoader
//       },
//       {
//         path: 'smschat',
//         element: <SMSChat />,
//         loader: SMSChatLoader
//       },
//       {
//         path: '/viewallmessages',
//         element: <ViewAllMessages />,
//       },
//       {
//         path: '/merchant',
//         element: <Merchant />
//       },
//       {
//         path: '/usermanagement',
//         element: <UserManagement />
//       },
//       {
//         path: '/subscription',
//         element: <Subscription />
//       }, 
//       {
//         path: '/customersmanagement',
//         element: <CustomersManagement />
//       },
//       {
//         path: '/optin',
//         element: <OptInForm />,
//       },
//       {
//         path: '/signup',
//         element: <Signup />
//       },
//       {
//         path: 'register',
//         element: <Register />
//       },
//       {
//         path: 'floatingdialog',
//         element: <FloatingDialog />
//       },
//       {
//         path: 'testgoogle',
//         element: <TestGoogleLogin />
//       }
//     ],
//   }
// ])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
