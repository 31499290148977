// import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Card } from 'react-bootstrap';

function Subscription() {
    return (
        <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>

            <Form>
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    <Form.Group as={Row} className="mb-3" controlId="formGridAddress1">
                                        <Form.Text className="text-muted">Free</Form.Text>
                                        <Form.Text className="text-muted">Start your trial</Form.Text>

                                    </Form.Group>
                                </Card.Title>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Control type='button' value='Subscribe' />
                                </Form.Group>
                            </Card.Header>

                            <Card.Body>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Text className="text-muted">Up to 1000 SMSs</Form.Text>
                                </Form.Group>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    <Form.Group  as={Row} className="mb-3" controlId="formGridAddress1">
                                        <Form.Text className="text-muted">Paid</Form.Text>
                                        <Form.Text className="text-muted">$29 per month</Form.Text>
                                    </Form.Group>
                                </Card.Title>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Control type='button' value='Buy' />
                                </Form.Group>
                            </Card.Header>

                            <Card.Body>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Text className="text-muted">Up to 1,000 SMSs per Month</Form.Text>
                                </Form.Group>
                            </Card.Body>

                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    <Form.Group as={Row} className="mb-3" controlId="formGridAddress1">
                                        <Form.Text className="text-muted">More SMS</Form.Text>
                                        <Form.Text className="text-muted">1,000 SMS for $19</Form.Text>

                                    </Form.Group>
                                </Card.Title>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Control type='button' value='Buy' />
                                </Form.Group>
                            </Card.Header>

                            <Card.Body>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Text className="text-muted">Up to 1000 SMSs</Form.Text>
                                </Form.Group>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>

            </Form>
        </Container>

    );
}

export default Subscription;