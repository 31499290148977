import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { useProfileContext } from "../home/Layout";
import axios from "axios";
import { REACT_APP_API_TRUEUP, REACT_APP_API_TRUEUP_WS } from "../config";

const API_URL = REACT_APP_API_TRUEUP_WS;
// const API_URL = "ws://127.0.0.1:8000"; // Uncomment this line for local development
const HTTP_API_URL = REACT_APP_API_TRUEUP; // Add this for HTTP requests
// const HTTP_API_URL = "http://127.0.0.1:8000"; // Uncomment this line for local development

function DraftPreview() {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [websocket, setWebsocket] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);
  const [previewCustomers, setPreviewCustomers] = useState([]);
  const [generatedMessages, setGeneratedMessages] = useState([]);
  const { googleProfile, sessionContext } = useProfileContext();
  const [sessionMerchantId, setSessionMerchantId] = useState(
    sessionContext && sessionContext.merchantId
  );
  const messagesEndRef = useRef(null);
  const [isMessageComplete, setIsMessageComplete] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (googleProfile && googleProfile.id_token) {
      setIdToken(googleProfile.id_token);
    }
  }, [googleProfile]);

  useEffect(() => {
    if (!idToken) return;

    console.log("sessionMerchantId:", sessionMerchantId);
    const clientId = Math.floor(Math.random() * 1000000);
    const ws = new WebSocket(`${API_URL}/drafts/ws/${clientId}?merchant_id=${sessionMerchantId}`);

    ws.onopen = () => {
      console.log("WebSocket connected");
      // Send the id token as the first message
      ws.send(JSON.stringify({ id_token: idToken }));
    };

    ws.onmessage = (event) => {
      console.log("Received message:", event.data); // Log every received message
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, { text: message.text, sender: "ai" }]);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    setWebsocket(ws);

    return () => {
      ws.close();
    };
  }, [sessionMerchantId, idToken]); // Add sessionMerchantId and idToken as dependencies

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (sessionMerchantId && idToken) {
      fetchRules();
    }
  }, [sessionMerchantId, idToken]);

  const fetchRules = async () => {
    try {
      const response = await axios.get(`${HTTP_API_URL}/rules/${sessionMerchantId}/list`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      setRules(response.data);
    } catch (error) {
      console.error("Error fetching rules:", error);
    }
  };

  const sendMessage = () => {
    if (inputMessage.trim() && websocket) {
      // Update the message format
      websocket.send(JSON.stringify({ message: inputMessage }));
      setMessages((prevMessages) => [...prevMessages, { text: inputMessage, sender: "user" }]);
      setInputMessage("");
    }
  };

  const handleWebSocketMessage = (data) => {
    const sendMessage = () => {
      if (inputMessage.trim() && websocket) {
        websocket.send(JSON.stringify({ feedback: inputMessage }));
        setMessages((prevMessages) => [...prevMessages, { text: inputMessage, sender: "user" }]);
        setInputMessage("");
      }
    };
    if (data.updated_prompt) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.updated_prompt, sender: "bot" },
      ]);
      setGeneratedMessages(data.new_messages);
    } else if (data.status === "messages_saved") {
      // Handle successful message saving (e.g., show success message)
    }
  };

  const generateMessages = async () => {
    try {
      const response = await axios.post(`${HTTP_API_URL}/generate-messages`, {
        customers: previewCustomers,
      });
      setGeneratedMessages(response.data.messages);
    } catch (error) {
      console.error("Error generating messages:", error);
    }
  };

  const regenerateMessages = async () => {
    // Call the preview API to regenerate messages
    await generateMessages();
  };

  const finalizeMessages = async () => {
    if (websocket) {
      websocket.send(JSON.stringify({ confirm_messages: true }));
    }
  };

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      <Row className="justify-content-center">
        <Col md={12} lg={9}>
          <Card>
            <Card.Header as="h5">Draft Text Messages</Card.Header>
            <Card.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  Select Rule:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    value={selectedRule || ""}
                    onChange={(e) => setSelectedRule(e.target.value)}
                  >
                    <option value="">Choose a rule...</option>
                    {rules.map((rule) => (
                      <option key={rule.id} value={rule.id}>
                        {rule.rule_description}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Button onClick={generateMessages} disabled={!selectedRule}>
                Generate Preview Messages
              </Button>

              <div
                className="chat-messages"
                style={{ height: "400px", overflowY: "auto", marginBottom: "20px" }}
              >
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-2 text-${message.sender === "user" ? "end" : "start"}`}
                  >
                    <Card
                      bg={message.sender === "user" ? "primary" : "light"}
                      text={message.sender === "user" ? "white" : "dark"}
                      style={{ display: "inline-block", maxWidth: "70%" }}
                    >
                      <Card.Body>
                        <Card.Text>
                          <ReactMarkdown>{message.text}</ReactMarkdown>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>

              <Card>
                <Card.Header as="h6">Generated Messages</Card.Header>
                <Card.Body>
                  {generatedMessages.map((message, index) => (
                    <div key={index}>
                      <p>
                        <strong>Customer {index + 1}:</strong> {message}
                      </p>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Control
                      type="text"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      placeholder="Type your message..."
                    />
                  </Col>
                  <Col xs="auto">
                    <Button type="submit" variant="primary">
                      Send
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button onClick={regenerateMessages} variant="secondary">
                      Regenerate Messages
                    </Button>
                  </Col>
                  {isMessageComplete && (
                    <Col xs="auto">
                      <Button onClick={finalizeMessages} variant="success">
                        Finalize Messages
                      </Button>
                    </Col>
                  )}
                </Form.Group>
              </Form>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DraftPreview;
