import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

const Signup = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const handleGoogleLogin = () => {
        // TODO: Implement Google login logic here
        // Use Google API to authenticate the user and retrieve their information
        // Set the retrieved first name, last name, and email in the component state
    };

    const handleSignup = async () => {
        try {
            // Call the Trueup backend API to create a merchant
            const response = await fetch('https://api.gettrueup.com/signup', {
                method: 'POST',
                body: JSON.stringify({
                    firstName,
                    lastName,
                    email,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Redirect the user to the merchant page upon successful sign-up
                window.location.href = '/merchant';
            } else {
                // Handle error response from the API
                const errorData = await response.json();
                console.error('Sign-up failed:', errorData);
                // TODO: Display an error message to the user
            }
        } catch (error) {
            console.error('Sign-up failed:', error);
            // TODO: Display an error message to the user
        }
    };

    return (
        <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
            <Row>
                <h2>Sign up an merchant account</h2>
            </Row>

            <Row>
                <Row><h6>Merchant Avarta</h6></Row>
                <Row>
                    <Col md={4}>
                        <Image src="/trueup.jpg" width={100} rounded />
                    </Col>
                    {/* <Col md={1}>
                        <Form>
                            <Button variant=''>Remove</Button>

                        </Form>
                    </Col>
                    <Col md={1}>
                        <Form>
                            <Button variant=''>Change</Button>

                        </Form>
                    </Col> */}
                </Row>
            </Row>
            <Row>
                <Row>
                    <h6>Merchant Name *</h6>
                </Row>
                <Row className="mb-3">
                    <Col md="5">
                        <Form.Group as={Col} controlId="formMerchant">
                            {/* <Form.Control>{"TechArtisan LLC"}</Form.Text> */}
                            <Form.Control placeholder="Enter your business's name" />

                        </Form.Group>
                    </Col>
                    {/* <Col>
                        <Button variant=''>
                            Change
                        </Button>
                    </Col> */}


                </Row>
            </Row>
            <Row>
                <Row>
                    <h6>Select an available phone number*</h6>
                    <p>The number is used to send and receive short message (SMS)</p>
                </Row>
                <Row className="mb-3">
                    <Col md="5">
                        <Form.Select aria-label="Select an available phone number">
                            {/* <option>Open this select menu</option> */}
                            <option value="1">+18880506060</option>
                            <option value="2">+14080506060(Local)</option>
                            <option value="3">+18000506060</option>
                        </Form.Select>
                    </Col>
                    {/* <Col>
                        <Button variant=''>
                            Choose
                        </Button>
                    </Col> */}
                </Row>
            </Row>
            <Row>
                <Row>
                    <h6>Contact</h6>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Group as={Col} controlId="formMerchant">
                            <Form.Control placeholder='First Name' />
                        </Form.Group>
                    </Col>
                    <Col md="2">
                        <Form.Group as={Col} controlId="formMerchant">
                            <Form.Control placeholder='Last Name' />
                        </Form.Group>
                    </Col>
                </Row>
            </Row>
            <Row>
                <Form>
                    <Button variant='primary' onClick={handleSignup}>Submit</Button>
                </Form>
            </Row>
        </Container>

    );
};

export default Signup;