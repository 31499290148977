import React, { useState, useEffect } from 'react';
import { Container, Row, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useProfileContext } from "../home/Layout";
// import { useExecutionContext } from "../authentication/useExecutionContext";
import { REACT_APP_API_TRUEUP, REACT_APP_API_SMS } from '../config';
import { Modal, Button, Form } from 'react-bootstrap';


const SuperadminLoginAsMerchant = () => {
    const [merchants, setMerchants] = useState([]);
    const { googleProfile, setUserSession } = useProfileContext();
    // const { setUserSession } = useExecutionContext();
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [merchantInfo, setMerchantInfo] = useState(null);
    const [senderPhoneNumber, setSenderPhoneNumber] = useState(null); // State for sender phone number

    const [showModal, setShowModal] = useState(false);
    const [merchantToShutdown, setMerchantToShutdown] = useState(null);
    const [inputName, setInputName] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message

    useEffect(() => {
        if (googleProfile) {
            const fetchMerchants = async () => {
                try {
                    const response = await axios.get(`${REACT_APP_API_TRUEUP}merchants`, {
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Bearer ${googleProfile.id_token}`,
                        }
                    });
                    setMerchants(response.data); // Assuming response.data is the array of merchants
                } catch (error) {
                    console.error('There was an error fetching merchants!', error);
                }
            };

            fetchMerchants();
        }
    }, [googleProfile]);

    const handleActAs = async (merchantId) => {
        try {
            const response = await axios.get(`${REACT_APP_API_TRUEUP}merchants/${merchantId}`, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                }
            });
            console.log(`response: ${JSON.stringify(response)}`);
            setMerchantInfo(response.data.merchant_info); // Directly access merchant_info
            setSenderPhoneNumber(response.data.sender_phone_number.phone_number); // Directly access sender_phone_number
            setSelectedMerchant(merchantId); // Store selected merchant ID

            setUserSession(googleProfile, merchantId, true);
            console.log(`senderPhoneNumber: ${senderPhoneNumber}`);
            console.log(`merchantId: ${merchantId}`);
        } catch (error) {
            console.error('There was an error fetching merchant info!', error);
        }
    };

    // Function to handle the shutdown of a merchant
    const handleShutdown = async () => {
        // console.log(`merchantToShutdown: ${merchantToShutdown}`);
        // console.log(`inputName: ${inputName}`);
        // if (inputName === merchantToShutdown[1]) {
        //     try {
        //         const response = await axios.delete(`${REACT_APP_API_SMS}sms-api/merchants/${merchantToShutdown[0]}/delete`);
        //         if (response.status === 200) {
        //             // Refresh the page after successful deletion
        //             window.location.reload();
        //         }
        //     } catch (error) {
        //         console.error('Error deleting merchant:', error);
        //         // Optionally, show an error message to the user
        //     }
        // }
        // // Close the modal after handling
        // setShowModal(false);
        if (inputName === merchantToShutdown[1]) {
            try {
                const response = await axios.delete(`${REACT_APP_API_SMS}sms-api/merchants/${merchantToShutdown[0]}/delete`);
                if (response.status === 200) {
                    // Refresh the page after successful deletion
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error deleting merchant:', error);
                // Optionally, show an error message to the user
            }
            setShowModal(false); // Close the modal after successful deletion
        } else {
            setErrorMessage("The merchant's name does not match the input. Please try again."); // Set error message
        }
    };

    // Function to open the confirmation modal
    const openModal = (merchant) => {
        setMerchantToShutdown(merchant);
        setInputName(''); // Reset input name
        setErrorMessage(''); // Reset error message
        setShowModal(true);
    };

    return (
        <main>
            <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                {googleProfile ? (
                    <>
                        <Row>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {merchants.map((merchant) => (
                                        <tr key={merchant[0]}>
                                            <td>{merchant[0]}</td>
                                            <td>{merchant[1]}</td>
                                            <td>
                                                <button onClick={() => handleActAs(merchant[0])}>
                                                    Act As
                                                </button>
                                                <Button
                                                    onClick={() => openModal(merchant)}
                                                    variant="danger"
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    Shutdown
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        {merchantInfo && (
                            <div>
                                <h3>Merchant Info</h3>
                                <p>Name: {merchantInfo.name}</p>
                                <p>Phone: {merchantInfo.phone}</p>
                                <p>Email: {merchantInfo.email}</p>
                                <p>Sender Phone Number: {senderPhoneNumber}</p> {/* Display sender phone number */}
                                {/* Add more fields as necessary */}
                            </div>
                        )}
                        {/* Confirmation Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Shutdown</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure you want to shut down the merchant? This action cannot be undone. If you confirm, please type the merchant's name below:</p>
                                <Form.Group>
                                    <Form.Label>Merchant Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={inputName}
                                        onChange={(e) => setInputName(e.target.value)}
                                        placeholder="Type merchant's name"
                                    />
                                </Form.Group>
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* Display error message */}
                                </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleShutdown}>
                                    Confirm Shutdown
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : (
                    <Alert color="danger" className="d-none d-lg-block">
                        <strong>Please login first</strong>
                    </Alert>
                )}
            </Container>
        </main>
    );
};

export default SuperadminLoginAsMerchant;
