import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Alert } from "react-bootstrap";
import { Navbar, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import { REACT_APP_API_TRUEUP } from "../config";
import { useProfileContext } from "../home/Layout";
import CampaignMessagePreview from "./CampaignMessagePreview";

// ... existing imports ...

export default function CampaignPreview() {
  const [campaignPreview, setCampaignPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [SMSs, setSMSs] = useState([]);

  const { googleProfile, sessionContext } = useProfileContext();
  const [merchantId, setMerchantId] = useState(sessionContext?.merchantId);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampaignPreview = async () => {
      if (!googleProfile || !merchantId) {
        setLoading(false);
        return;
      }

      const searchParams = new URLSearchParams(location.search);
      const campaignId = searchParams.get("campaignId");

      if (!campaignId) {
        setError("No campaign ID provided");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_APP_API_TRUEUP}campaigns/${merchantId}/preview/${campaignId}`,
          {
            headers: {
              Authorization: `Bearer ${googleProfile.id_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setCampaignPreview(response.data);
      } catch (err) {
        setError("Failed to fetch campaign preview");
        console.error("Error fetching campaign preview:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignPreview();
  }, [googleProfile, merchantId, location.search]);

  const handleBackButtonClick = () => {
    navigate("/campaigns");
  };

  const handleSendAllButtonClick = () => {
    // Implement the logic to send messages to all customers
    alert("Sending messages to all customers in the campaign");
  };

  const updateCustomers = (updatedCustomer) => {
    setCampaignPreview((prevState) => ({
      ...prevState,
      matching_customers: prevState.matching_customers.map((customer) =>
        customer.id === updatedCustomer.id ? updatedCustomer : customer
      ),
    }));
  };

  const refreshCustomers = () => {
    // Implement logic to refresh customers if needed
    console.log("Refreshing customers...");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!campaignPreview) {
    return <Alert variant="warning">No campaign preview data available</Alert>;
  }

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      {googleProfile ? (
        <>
          <h2>{campaignPreview.campaign.name}</h2>
          <p>{campaignPreview.campaign.target_audience_description}</p>

          <Row>
            {campaignPreview.matching_customers.map((customer) => (
              <Col key={customer.id} xs={12} md={6} lg={4} className="mb-4">
                <CampaignMessagePreview
                  merchantId={merchantId}
                  merchantPhoneNumber={sessionContext?.merchantPhoneNumber}
                  customerId={customer.id}
                  customerName={customer.name}
                  customerPhone={customer.phone_number}
                  initialDraft={customer.populated_message}
                  onUpdateDraft={(updatedDraft) => {
                    updateCustomers({
                      ...customer,
                      populated_message: updatedDraft,
                    });
                  }}
                  onMessageSent={(sentMessage) => {
                    // Handle sent message if needed
                    console.log("Message sent:", sentMessage);
                    refreshCustomers();
                  }}
                />
              </Col>
            ))}
          </Row>

          <Row>
            <Container>
              <Navbar fixed="bottom" bg="light">
                <Nav className="ms-auto">
                  <Button variant="secondary" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <Button variant="primary" className="ms-2" onClick={handleSendAllButtonClick}>
                    Send All
                  </Button>
                </Nav>
              </Navbar>
            </Container>
          </Row>
        </>
      ) : (
        <Alert variant="danger">
          <strong>Please login first</strong>
        </Alert>
      )}
    </Container>
  );
}
