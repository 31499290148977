import { useState, useEffect } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';

import { REACT_APP_API_TRUEUP} from "./config";
// import { businessowners } from "./businessowners.mjs";
import { useProfileContext } from "./home/Layout";
import SMSDialogViewOnly from "./SMSDialogViewOnly";

export default function ViewAllMessages() {
    const [customers, setCustomers] = useState([])
    const { googleProfile } = useProfileContext();

    useEffect(() => {
        if (googleProfile) {
            const data = {
                email: googleProfile.email,
            };

//            axios.post('https://www.laplace-innovations.com/api/customers', data, {
            axios.post(`${REACT_APP_API_TRUEUP}customers`, data, {
                headers: {
                    'accept': 'application/json',
                    // 'x-api-key': REACT_APP_X_API_KEY,
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data);
                    setCustomers(response.data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

        } else {
            setCustomers([]);
        }
    }, [googleProfile])

    return (
        <Container className="px-0" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
            <Row
                className="pt-2 pt-md-5 w-100 
                        px-4 px-xl-0 position-relative"
            >
                {
                    customers.map((customer) => {
                        return (
                            <Col
                                key={customer.id} // Assuming 'customer' has a unique 'id' property
                                xs={3}
                                md={3}
                                lg={3}
                                tag="aside"
                                className="pb-5 mb-5 pb-md-0 
                            mb-md-0 mx-auto mx-md-0"
                            >
                                <SMSDialogViewOnly>{customer}</SMSDialogViewOnly>
                            </Col>
                        )
                    }
                    )
                }
                {googleProfile ? (
                    <></>
                ) : (
                    <Alert color="danger" className="d-none d-lg-block">
                        <strong>Please login first</strong>
                    </Alert>
                )
                }
            </Row>

        </Container>
    )
}