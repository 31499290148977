// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-container {
  height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.campaign-form-col {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}

.campaign-form-col form {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 1rem; /* Add some padding to account for potential scrollbar */
  height: calc(100% - 60px); /* Adjust based on your h2 height */
}

.chat-ui-col {
  height: 100%;
  overflow-y: auto;
}

/* Ensure form elements don't overflow */
.campaign-form-col form .form-control {
  max-width: 100%;
  resize: none;
}

/* Adjust badge layout */
.keyword-badges {
  display: flex;
  flex-wrap: wrap;
}

.keyword-badge {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.customer-summary {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.customer-summary ul {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.customer-summary li {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/campaign/Campaign.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB,EAAE,wDAAwD;EAC7E,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA,wCAAwC;AACxC;EACE,eAAe;EACf,YAAY;AACd;;AAEA,wBAAwB;AACxB;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".campaign-container {\n  height: 100vh;\n  padding-top: 2rem;\n  padding-bottom: 2rem;\n}\n\n.campaign-form-col {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow-x: hidden; /* Prevent horizontal scrollbar */\n}\n\n.campaign-form-col form {\n  flex-grow: 1;\n  overflow-y: auto;\n  padding-right: 1rem; /* Add some padding to account for potential scrollbar */\n  height: calc(100% - 60px); /* Adjust based on your h2 height */\n}\n\n.chat-ui-col {\n  height: 100%;\n  overflow-y: auto;\n}\n\n/* Ensure form elements don't overflow */\n.campaign-form-col form .form-control {\n  max-width: 100%;\n  resize: none;\n}\n\n/* Adjust badge layout */\n.keyword-badges {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.keyword-badge {\n  margin-right: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.customer-summary {\n  background-color: #f8f9fa;\n  border: 1px solid #dee2e6;\n  border-radius: 0.25rem;\n  padding: 1rem;\n  max-height: 200px;\n  overflow-y: auto;\n}\n\n.customer-summary ul {\n  padding-left: 1.5rem;\n  margin-bottom: 0;\n}\n\n.customer-summary li {\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
