import React, { useState, useEffect } from 'react';
import { useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { Button, Container, Nav, Row, Table, Form } from 'react-bootstrap';
import { Navbar, Alert } from 'react-bootstrap';

import axios from 'axios';

import { REACT_APP_API_TRUEUP, REACT_APP_X_API_KEY } from './globalvariable';
import { useProfileContext } from "./home/Layout";
// import { businessowners } from "./businessowners.mjs";

export async function loader({ request }) {
    console.log('loader', request.url);
    const url = new URL(request.url);
    const q = url.searchParams.get('q') || '';

    return { q };
}

export default function CustomerList() {
    console.log('CustomerList');
    console.log('REACT_APP_API_TRUEUP=' + REACT_APP_API_TRUEUP);
    console.log('REACT_APP_X_API_KEY=' + REACT_APP_X_API_KEY);
    
    const [customers, setCustomers] = useState([]);
    const [searchedCustomers, setSearchedCustomers] = useState(customers);

    const { googleProfile } = useProfileContext();

    const navgiate = useNavigate();

    const [selectedCustomers, setSelectedCustomers] = useState([]);

    const [filter, setFilter] = useState('');

    // const filteredCustomers = customers.filter((customer) =>
    //     customer.name.toLowerCase().includes(filter.toLowerCase())
    // );

    const { q } = useLoaderData();
    const submit = useSubmit();

    const searchCustermers = (q) => {
        
        console.log("searchCustermers q=" + q);
        console.log("searchCustermers customers=" + JSON.stringify(customers));
        console.log("searchCustermers searchedCustomers=" + JSON.stringify(searchedCustomers));
        // setSearchedCustomers(customers);

        if (!q) {
            setSearchedCustomers(customers);
            return
        }


        let newSearchedCustomers = customers.filter((customer) =>
            (customer.name && customer.name.toLowerCase().includes(q.toLowerCase())) 
            // || customer.phone.toLowerCase().includes(q.toLowerCase())
            // || customer.email.toLowerCase().includes(q.toLowerCase())
            || (customer.serviceAppointed && customer.serviceAppointed.toLowerCase().includes(q.toLowerCase()))
            || (customer.draft && customer.draft.toLowerCase().includes(q.toLowerCase()))
            || (customer.tag && customer.tag.toLowerCase().includes(q.toLowerCase()))
        );
        setSearchedCustomers(newSearchedCustomers)

        console.log("searchCustermers customers=" + JSON.stringify(customers));
        console.log("searchCustermers searchedCustomers=" + JSON.stringify(searchedCustomers));
    }

    
    const handleCheckboxChange = (customerId) => {
        setSelectedCustomers((prevSelected) =>
            prevSelected.includes(customerId)
                ? prevSelected.filter((id) => id !== customerId)
                : [...prevSelected, customerId]
        );
    };

    const handleSelectAllCustomers = (e) => {
        // alert(e.target.checked)
        if (e.target.checked) {
            setSelectedCustomers([]); //Fix bug that if you alread select some, it will reverse selection
            customers.map((customer) => { return handleCheckboxChange(customer.id) });
        } else {
            setSelectedCustomers([]);
        }

    }

    function handleButtonClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
        if (selectedCustomers.length === 0) {
            alert("Please select at leasts one customer!");
            return;
        };

        const encodeArrary = encodeURIComponent(JSON.stringify(selectedCustomers));
        navgiate(`/screenchat?selectedCustomers=${encodeArrary}`);
        // navgiate(`/smschat?selectedCustomers=${encodeArrary}`);

    }


    useEffect(() => {
        console.log('useEffect');
        if (googleProfile) {
            const data = {
                email: googleProfile.email,
            };

            axios.post(`${REACT_APP_API_TRUEUP}customers`, data, {
            // axios.post(process.env.REACT_APP_API_TRUEUP + "customers", data, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': REACT_APP_X_API_KEY,
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data);
                    // alert(data);
                    setCustomers(response.data);
                    setSearchedCustomers(response.data);
                    if (q) {
                        searchCustermers(q);
                    }
                    // searchCustermers(q);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

        } else {
            setCustomers([]);
        }
    }, [googleProfile, q])

    return (
        <main>
            <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                {googleProfile ? (
                    <>
                        <Row>
                            <div id="searchDiv">
                                <Form id="search-form" role="search">
                                    <input
                                        id="q"
                                        aria-label="Search customers"
                                        placeholder="Search"
                                        type="search"
                                        name="q"
                                        // defaultValue={q}
                                        value={filter}
                                        onChange={(e) => {
                                            setFilter(e.target.value);
                                            submit(e.currentTarget.form);
                                        }}
                                    />
                                    <div
                                        id="search-spinner"
                                        aria-hidden
                                        hidden={true}
                                    />
                                    <div
                                        className="sr-only"
                                        aria-live="polite"
                                    ></div>
                                </Form>
                            </div>
                        </Row>
                        <Row>
                            {/* <Table className="table" striped bordered hove> */}
                            <Table hover>

                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox" onChange={handleSelectAllCustomers}
                                            />
                                        </th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Last Visit</th>
                                        <th>Service Appointed</th>
                                        <th>Message preview</th>
                                        <th>Tag</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {customers.map((customer) => ( */}
                                    {searchedCustomers.map((customer) => (

                                        <tr key={customer.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCustomers.includes(customer.id)}
                                                    onChange={() => handleCheckboxChange(customer.id)}
                                                />
                                            </td>
                                            <td>{customer.name}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.email}abc@efg.com</td>
                                            <td>{customer.lastVisit}</td>
                                            <td>{customer.serviceAppointed}</td>
                                            <td>{customer.draft}</td>
                                            <td>{customer.tag}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Container>
                                <Navbar fixed="bottom" bg="light">
                                    <Nav className='ms-auto'>
                                        <Button variant='primary' onClick={handleButtonClick}>Next</Button>
                                    </Nav>
                                </Navbar>
                            </Container>
                        </Row>
                    </>)
                    : (
                        <Alert color="danger" className="d-none d-lg-block">
                            <strong>Please login first</strong>
                        </Alert>
                    )

                }
            </Container>
        </main>
    );
}
// export default CustomerList;
