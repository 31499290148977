// Import the necessary libraries
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button, TextArea } from "react-bootstrap";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useProfileContext } from "../home/Layout";
import ReactMarkdown from "react-markdown";
import { REACT_APP_API_TRUEUP } from "../config";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Configuration
const API_ENDPOINT = REACT_APP_API_TRUEUP;

// Define the Dashboard component
function Dashboard() {
  const [data, setData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [trailingMonths, setTrailingMonths] = useState(12);
  const [recencyBreakpoints, setRecencyBreakpoints] = useState({
    active_months: 2,
    cold_months: 4,
    dormant_months: 6,
    lost_months: 8,
  });
  const [retentionMonths, setRetentionMonths] = useState(3);

  // Add these new state variables
  const [activeMonths, setActiveMonths] = useState(recencyBreakpoints.active_months);
  const [coldMonths, setColdMonths] = useState(recencyBreakpoints.cold_months);
  const [dormantMonths, setDormantMonths] = useState(recencyBreakpoints.dormant_months);
  const [lostMonths, setLostMonths] = useState(recencyBreakpoints.lost_months);
  const [combinedMetrics, setCombinedMetrics] = useState(null);

  const [recommendationsHeight, setRecommendationsHeight] = useState("200px");

  useEffect(() => {
    if (combinedMetrics) {
      const contentLength = combinedMetrics.length;
      let newHeight = "200px"; // Default height

      if (contentLength > 500 && contentLength <= 1000) {
        newHeight = "300px";
      } else if (contentLength > 1000) {
        newHeight = "400px";
      }

      setRecommendationsHeight(newHeight);
    }
  }, [combinedMetrics]);

  // Define panel dimensions
  const panelWidth = 500;
  const panelHeight = 300;

  const { googleProfile, sessionContext } = useProfileContext();

  const [sessionMerchantId, setSessionMerchantId] = useState(
    sessionContext && sessionContext.merchantId
  );

  // Define data for panel1, panel2, panel3, panel4
  useEffect(() => {
    const fetchData = async () => {
      if (!googleProfile) {
        console.error("User not logged in");
        return;
      }

      try {
        const baseUrl = API_ENDPOINT;
        const currentMerchantId = sessionMerchantId;

        const headers = {
          Authorization: `Bearer ${googleProfile.id_token}`,
          "Content-Type": "application/json",
        };

        // Fetch monthly customers data
        const monthlyCustomersResponse = await fetch(
          `${baseUrl}/metrics/${currentMerchantId}/monthly_customers`,
          { headers }
        );
        const monthlyCustomersData = await monthlyCustomersResponse.json();

        // Get the current date
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Add 1 because get month returns 0-11
        const currentYear = currentDate.getFullYear();
        // Log current date, month, and year
        console.log("Current Date:", currentDate);
        console.log("Current Month:", currentMonth);
        console.log("Current Year:", currentYear);

        // Find the index where future months start
        const futureStartIndex =
          monthlyCustomersData.months.findIndex((month) => {
            const [year, monthNum] = month.split("-").map(Number);
            return year > currentYear || (year === currentYear && monthNum >= currentMonth);
          }) - 1;

        // Fetch customer frequency data
        const customerFrequencyResponse = await fetch(
          `${baseUrl}/metrics/${currentMerchantId}/customer_frequency`,
          {
            method: "POST",
            headers,
            body: JSON.stringify({ trailing_months: trailingMonths }),
          }
        );
        const customerFrequencyData = await customerFrequencyResponse.json();

        // Fetch customer recency data
        const customerRecencyResponse = await fetch(
          `${baseUrl}/metrics/${currentMerchantId}/customer_recency`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(recencyBreakpoints),
          }
        );
        const customerRecencyData = await customerRecencyResponse.json();

        // Fetch customer retention data
        const customerRetentionResponse = await fetch(
          `${baseUrl}/metrics/${currentMerchantId}/customer_retention`,
          {
            method: "POST",
            headers,
            body: JSON.stringify({ trailing_months: retentionMonths }),
          }
        );
        const customerRetentionData = await customerRetentionResponse.json();

        const getBorderDash = (ctx) => {
          return ctx.p0DataIndex >= futureStartIndex ? [5, 5] : undefined;
        };

        setData({
          panel_monthly_customers: {
            labels: monthlyCustomersData.months,
            datasets: [
              {
                label: "Monthly Customers",
                data: monthlyCustomersData.customer_counts,
                fill: false,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
                segment: {
                  borderDash: getBorderDash,
                },
              },
            ],
          },
          panel_customer_frequency: {
            labels: ["1 Visit", "2 Visits", "3+ Visits"],
            datasets: [
              {
                data: [
                  customerFrequencyData.one_transaction,
                  customerFrequencyData.two_transactions,
                  customerFrequencyData.three_plus_transactions,
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.6)",
                  "rgba(54, 162, 235, 0.6)",
                  "rgba(75, 192, 192, 0.6)",
                ],
              },
            ],
          },
          panel_customer_recency: {
            labels: customerRecencyData.labels.map((label, index) => {
              const currentMonths =
                customerRecencyData.breakpoints[`${label.toLowerCase()}_months`];
              const previousLabel = customerRecencyData.labels[index - 1];
              const previousMonths = previousLabel
                ? customerRecencyData.breakpoints[`${previousLabel.toLowerCase()}_months`]
                : 0;

              if (label === "Active") {
                return `${label} (≤${currentMonths} ${currentMonths === 1 ? "month" : "months"})`;
              } else {
                const lowerBound = previousMonths + 1;
                if (lowerBound === currentMonths) {
                  return `${label} (${lowerBound} ${lowerBound === 1 ? "month" : "months"})`;
                } else {
                  return `${label} (${lowerBound}-${currentMonths} ${
                    currentMonths === 1 ? "month" : "months"
                  })`;
                }
              }
            }),
            datasets: [
              {
                label: "Customers",
                data: customerRecencyData.values,
                backgroundColor: [
                  "rgba(75, 192, 192, 0.6)",
                  "rgba(255, 206, 86, 0.6)",
                  "rgba(255, 159, 64, 0.6)",
                  "rgba(255, 99, 132, 0.6)",
                ],
              },
            ],
          },
          panel_customer_retention: customerRetentionData,
        });

        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsDataLoaded(false);
      }
    };

    fetchData();
  }, [googleProfile, trailingMonths, recencyBreakpoints, retentionMonths, sessionMerchantId]);

  useEffect(() => {
    if (isDataLoaded) {
      fetchCombinedMetrics();
    }
  }, [isDataLoaded]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value, ctx) => {
          const dataset = ctx.chart.data.datasets[0];
          const total = dataset.data.reduce((acc, data) => acc + data, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value}\n(${percentage}%)`;
        },
      },
    },
    scales: {
      x: {
        type: "category",
        labels: data?.panel_monthly_customers?.labels || [],
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleTrailingMonthsChange = (event) => {
    setTrailingMonths(Number(event.target.value));
  };

  const handleRecencyBreakpointsChange = (event) => {
    const { name, value } = event.target;
    const newValue = Number(value);

    switch (name) {
      case "active_months":
        setActiveMonths(newValue);
        break;
      case "cold_months":
        setColdMonths(newValue);
        break;
      case "dormant_months":
        setDormantMonths(newValue);
        break;
      case "lost_months":
        setLostMonths(newValue);
        break;
      default:
        break;
    }

    setRecencyBreakpoints((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleRetentionMonthsChange = (event) => {
    setRetentionMonths(Number(event.target.value));
  };

  const fetchCombinedMetrics = async () => {
    if (!googleProfile || !isDataLoaded) {
      console.error("User not logged in or data not loaded");
      return;
    }

    try {
      const baseUrl = API_ENDPOINT;
      const currentMerchantId = sessionMerchantId;

      const headers = {
        Authorization: `Bearer ${googleProfile.id_token}`,
        "Content-Type": "application/json",
      };

      const metricsData = {
        monthly_customers: {
          months: data.panel_monthly_customers.labels,
          customer_counts: data.panel_monthly_customers.datasets[0].data,
        },
        customer_frequency: {
          one_transaction: data.panel_customer_frequency.datasets[0].data[0],
          two_transactions: data.panel_customer_frequency.datasets[0].data[1],
          three_plus_transactions: data.panel_customer_frequency.datasets[0].data[2],
          trailing_months: trailingMonths,
        },
        customer_recency: {
          labels: data.panel_customer_recency.labels,
          values: data.panel_customer_recency.datasets[0].data,
          breakpoints: recencyBreakpoints,
        },
        customer_retention: data.panel_customer_retention,
      };

      const response = await fetch(`${baseUrl}/metrics/${currentMerchantId}/combined_metrics`, {
        method: "POST",
        headers,
        body: JSON.stringify(metricsData),
      });

      const responseData = await response.json();
      // Extract the text from the analysis field
      const analysisText = responseData.analysis || "No recommendations available.";
      setCombinedMetrics(analysisText);
    } catch (error) {
      console.error("Error fetching combined metrics:", error);
      setCombinedMetrics("Error fetching recommendations.");
    }
  };

  if (!data) return <div>Loading...</div>;

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      <Form.Group className="mb-3">
        <Form.Label>Session Merchant ID:</Form.Label>
        <Form.Control type="text" value={sessionMerchantId} readOnly />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Select Trailing Months:</Form.Label>
        <Form.Select value={trailingMonths} onChange={handleTrailingMonthsChange}>
          <option value={12}>12 months</option>
          <option value={24}>24 months</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Select Retention Trailing Months:</Form.Label>
        <Form.Select value={retentionMonths} onChange={handleRetentionMonthsChange}>
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1} {i === 0 ? "month" : "months"}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Set Recency Breakpoints (in months):</Form.Label>
        <Row>
          <Col>
            <Form.Label>Active</Form.Label>
            <Form.Control
              type="number"
              name="active_months"
              value={activeMonths}
              onChange={handleRecencyBreakpointsChange}
              min={1}
            />
          </Col>
          <Col>
            <Form.Label>Cold</Form.Label>
            <Form.Control
              type="number"
              name="cold_months"
              value={coldMonths}
              onChange={handleRecencyBreakpointsChange}
              min={activeMonths + 1}
            />
          </Col>
          <Col>
            <Form.Label>Dormant</Form.Label>
            <Form.Control
              type="number"
              name="dormant_months"
              value={dormantMonths}
              onChange={handleRecencyBreakpointsChange}
              min={coldMonths + 1}
            />
          </Col>
          <Col>
            <Form.Label>Lost</Form.Label>
            <Form.Control
              type="number"
              name="lost_months"
              value={lostMonths}
              onChange={handleRecencyBreakpointsChange}
              min={dormantMonths + 1}
            />
          </Col>
        </Row>
      </Form.Group>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h5">Business Recommendations</Card.Header>
            <Card.Body>
              <div style={{ maxHeight: recommendationsHeight, overflowY: "auto" }}>
                <ReactMarkdown>{combinedMetrics || "No recommendations yet."}</ReactMarkdown>
              </div>
              <Button variant="primary" onClick={fetchCombinedMetrics} className="mt-2">
                Get Recommendations
              </Button>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                These are AI-generated business recommendations based on your data. Please review
                and use them at your discretion.
              </small>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <Card style={{ width: `${panelWidth}px`, height: `${panelHeight}px` }}>
            <Card.Header as="h5">Monthly Customers</Card.Header>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <div style={{ width: "100%", height: "100%" }}>
                <Line
                  data={data.panel_monthly_customers}
                  options={{
                    ...chartOptions,
                    maintainAspectRatio: false,
                    responsive: true,
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ width: `${panelWidth}px`, height: `${panelHeight}px` }}>
            <Card.Header as="h5">Customer Recency</Card.Header>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <div style={{ width: "100%", height: "100%" }}>
                <Bar
                  data={data.panel_customer_recency}
                  options={{
                    ...chartOptions,
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      ...chartOptions.plugins,
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: "Number of Customers",
                        },
                      },
                    },
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card style={{ width: `${panelWidth}px`, height: `${panelHeight}px` }}>
            <Card.Header as="h5">Promotion Planner</Card.Header>
            <Card.Body className="d-flex justify-content-center align-items-center">
              <div style={{ width: "100%", height: "100%" }}>
                <Pie
                  data={data.panel_customer_frequency}
                  options={{
                    ...chartOptions,
                    maintainAspectRatio: false,
                    responsive: true,
                  }}
                  plugins={[ChartDataLabels]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ width: `${panelWidth}px`, height: `${panelHeight}px` }}>
            <Card.Header as="h5">Customer Retention</Card.Header>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <h2>{(data.panel_customer_retention.retention_rate * 100).toFixed(2)}%</h2>
              <p>Retained: {data.panel_customer_retention.retained_customers}</p>
              <p>
                Previous ({retentionMonths + 1}-{retentionMonths * 2} months ago):{" "}
                {data.panel_customer_retention.previous_customers}
              </p>
              <p>
                Current (1-{retentionMonths} months ago):{" "}
                {data.panel_customer_retention.current_customers}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
