import { GoogleLogin } from '@react-oauth/google';
import { Container } from 'react-bootstrap';

function TestGoogleLogin() {
  const handleLogin = async (googleData) => {
    console.log(googleData);
    console.log(googleData.id_token);

    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.id_token, // Send id_token to your server
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });
    // const data = await res.json();
    // Handle the data from the server
  };

  return (
    <main>
    <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
      <h1>Google Login</h1>
    <div>
      <GoogleLogin
        useOneTap
        onSuccess={handleLogin}
        onError={() => console.log('Login Failed')}
      />
    </div>
    </Container>
    </main>
  );

}

export default TestGoogleLogin;
