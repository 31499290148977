import { useEffect, useRef, useState } from "react";
import { Card, InputGroup, Stack } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";

import { REACT_APP_X_API_KEY, REACT_APP_API_TRUEUP, REACT_APP_API_SMS } from "./globalvariable";

import { useProfileContext } from "./home/Layout";
import { businessowners } from "./businessowners.mjs";
// import { createSMS } from "./SMSs";

const SendDraftText = (props) => {
    const { googleProfile } = useProfileContext();
    // const [sender, setSender] = useState();

    const sender = businessowners[googleProfile.email].phone;

    // const [customer, setCustomer] = useState(children);
    // const [customer] = useState(props.customer);
    const { updateCustomers, refreshCustomers, setSMSs } = props;


    // const [isEdit, setIsEdit] = useState(false);
    // const [text, setText] = useState(typeof props.customer.draft === 'undefined' ? '' : props.customer.draft);
    // const [isSend, setIsSend] = useState(false)
    const [message, setMessage] = useState({
        from: sender,
        to: typeof props.customer.phone === 'undefined' ? '' : props.customer.phone,
        body: typeof props.customer.draft === 'undefined' ? '' : props.customer.draft
    })

    // const [clickCount, setClickCount] = useState(0)

    // const [historyMessages, setHistoryMessages] = useState(props.customer.messages);

    const handleInputChange = (e) => {
        // setText(e.target.value);
        setMessage(previousMsg => { return { ...previousMsg, body: e.target.value } })
        // setCustomer({ ...customer, draft: text });
        let newCustomer = { ...props.customer, draft: e.target.value };
        updateCustomers(newCustomer);
    };

    const updateMessage = async () => {
        if (props.customer.draft === null || props.customer.draft.trim() === '') {
            return;
        }

        const data = {
            "update": {
                "draft_message": props.customer.draft,
            },
            "merchant_customer": {
                "merchant_customer_id": props.customer.id
            },
            "user": {
                "email": googleProfile.email
            }
        };

        //        axios.post('https://www.laplace-innovations.com/api/customers/update/draft', data, {
        axios.post(`${REACT_APP_API_TRUEUP}customers/update/draft`, data, {
            headers: {
                'accept': 'application/json',
                'x-api-key': REACT_APP_X_API_KEY,
                'Authorization': `Bearer ${googleProfile.id_token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(`post ${REACT_APP_API_TRUEUP}customers/update/draft`);
                // alert(data);
                // setIsEdit(false);
                let newCustomer = { ...props.customer, draft: props.customer.draft };
                updateCustomers(newCustomer);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleInputBlur = () => {
        // if (props.customer.draft === null || props.customer.draft.trim() === '') {
        //     return;
        // }

        setMessage(previousMsg => { return { ...previousMsg, body: props.customer.draft } })
        // setCustomer({ ...customer, draft: text });
        let newCustomer = { ...props.customer, draft: props.customer.draft };
        updateCustomers(newCustomer);
        updateMessage()
    };

    const handleSendClick = () => {
        if (props.customer.draft === null || props.customer.draft.trim() === '') {
            alert("message could not be empty.");
            return;
        }
        console.log("handleSendClick:" + JSON.stringify(message));

        // fetch('https://www.summerfang.me/api/messages', {
        // fetch('http://localhost:5000/api/messages', {
        fetch(`${REACT_APP_API_SMS}messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': REACT_APP_X_API_KEY
            },
            body: JSON.stringify(message)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    const data = {
                        "send": {
                            "sender_phone_number": businessowners[googleProfile.email].phone,
                        },
                        "merchant_customer": {
                            "merchant_customer_id": props.customer.id
                        },
                        "user": {
                            "email": googleProfile.email
                        }
                    };

                    // alert(JSON.stringify(data));
                    //                    axios.post('https://www.laplace-innovations.com/api/customers/send', data, {

                    axios.post(`${REACT_APP_API_TRUEUP}customers/send`, data, {
                        headers: {
                            'accept': 'application/json',
                            'x-api-key': REACT_APP_X_API_KEY,
                            'Authorization': `Bearer ${googleProfile.id_token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => {
                            console.log(`POST ${REACT_APP_API_TRUEUP}customers/send`);
                            // alert(data);
                            // setIsEdit(false);
                            // setText('');

                            // setCustomer({ ...customer, draft: text });
                            let newCustomer = { ...props.customer, draft: "" };
                            updateCustomers(newCustomer);
                            refreshCustomers();
                            setMessage(previousMsg => { return { ...previousMsg, body: props.customer.draft } })

                            let newSMSs = props.SMSs;
                            newSMSs.push({ From: businessowners[googleProfile.email].phone, To: props.customer.phone, Body: props.customer.draft });
                            // [...props.SMSs, { From: businessowners[googleProfile.email].phone, To: props.customer.phone, Body: props.customer.draft }];
                            console.log("newSMSs:" + JSON.stringify(newSMSs));
                            setSMSs(newSMSs);
                            // createSMS({ From: businessowners[googleProfile.email].phone, To: props.customer.phone, Body: props.customer.draft });
                            // setSMSs((previousSMSs) => [...previousSMSs, { from: businessowners[googleProfile.email].phone, to: props.customer.phone, body: props.customer.draft }]);
                            // updateMessage();

                        })
                        .catch(error => {
                            console.error('There was an error!', error);
                            alert("Failed to update data.");
                        });

                    // setMessage(...message, { body: '' });
                    // setIsSend(true);
                } else {
                    // setIsSend(false);
                }
            });

        refreshCustomers();

        //Walkaround to refresh the page
        // setIsSMSSentUpdated(!props.isSMSSentUpdated);
    }

    const textareaStyles = {
        resize: 'vertical',
        maxHeight: '600px', // Set as needed
        height: 'auto', // Can be set here or in JS
        overflowY: 'auto',
        wordWrap: 'break-word',
    };



    return (
        <>
            <div
                style={{
                    height: 'auto',
                    color: 'white',
                    textAlign: 'left'
                }}
            >
                <InputGroup>
                    <Form.Control
                        as="textarea"
                        style={textareaStyles}
                        value={typeof props.customer === 'undefined' || props.customer.draft === null ? '' : props.customer.draft}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        placeholder="Enter your text here..."
                        rows={3}
                    />
                    <Button variant="primary" onClick={handleSendClick}>
                        <i className="bi bi-send"></i>
                    </Button>
                </InputGroup>
            </div>
        </>
    );
};

const SentText = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                background: 'ForestGreen',
                height: 'auto',
                color: 'white',
                borderRadius: '25px 25px 0px 25px',
                padding: '10pt',
                marginBottom: '10pt',
                marginLeft: '40pt',
                textAlign: 'left'
            }}
        >
            {children}
        </div>
    );
};
const ReceivedText = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                background: 'lightgrey',
                height: 'auto',
                color: 'black',
                borderRadius: '0px 25px 25px 25px',
                padding: '10px',
                marginBottom: '10px',
                marginRight: '40px',
                textAlign: 'left'
            }}
        >
            {children}
        </div>
    );
};

const headerStyle = {
    backgroundColor: 'WhiteSmoke',
};

//export default function SMSDialog({ children }: { children: React.ReactNode }) {
export default function SMSDialog(props) {

    // const [customer, setCustomer] = useState(children)
    // const { googleProfile } = useProfileContext();
    // const mechant_phone = businessowners[googleProfile.email].phone;

    // const [customer] = useState(props.customer)
    // const { updateCustomers, refreshCustomers } = props;
    // const [receivedSMSs, setreceivedSMSs] = useState(props.receivedSMSs);
    // // const [receivedSMS] = useState(props.receivedSMS);
    // const [historyMessages, setHistoryMessages] = useState(props.customer.messages);

    const msgEndRef = useRef(null);

    useEffect(() => {
        msgEndRef.current?.lastElementChild?.scrollIntoView();
    });

    return (
        <Card className="text-center" style={{ height: '500px' }}>
            <Card.Header style={headerStyle}>
                <Card.Title>
                    <Stack>
                        <h2><i className="bi bi-person-circle"></i></h2>{props.customer.name}({props.customer.phone})
                    </Stack>
                </Card.Title>
            </Card.Header>
            <Card.Body style={{ height: '1600px', overflowY: 'auto' }} ref={msgEndRef}>
                {
                    props.SMSs.map((sms, index) => {
                        console.log("sms:" + JSON.stringify(sms));
                        if (sms.To === props.customer.phone || sms.From === props.customer.phone) {
                          if (sms.From === props.customer.phone) {
                            return (
                              <SentText key={index}>
                                <div>
                                  {sms.Body}
                                </div>
                              </SentText>
                            )
                          } else if (sms.To === props.customer.phone) {
                            return (
                              <ReceivedText key={index}>
                                <div>
                                  {sms.Body}
                                </div>
                              </ReceivedText>
                            )
                          }
                        }

                    }
                    )
                }
            </Card.Body>
            <Card.Footer className="text-muted">
                <SendDraftText
                    updateCustomers={props.updateCustomers}
                    refreshCustomers={props.refreshCustomers}
                    customer={props.customer}
                    SMSs={props.SMSs}
                    setSMSs={props.setSMSs}
                // isSMSSentUpdated={props.isSMSSentUpdated}
                // setIsSMSSentUpdated={props.setIsSMSSentUpdated}
                >
                </SendDraftText>
            </Card.Footer>
        </Card>
    )
}