import axios from "axios";
// const axios = require('axios');

export default function RestfulAPICall(endpoint, { headers, body, method }, is_simulate = false) {
  // Mock data for simulation
  const testData = {
    '/users/exists': [
      {
        "user_id_exists": false,
        "user_id": null,
        "merchant_id_exists": false,
        "merchant_id": null
      },
      {
        "user_id_exists": true,
        "user_id": 123,
        "merchant_id_exists": true,
        "merchant_id": 123
      }
    ],
    '/phone-numbers/available': [
      {
        "id": 1,
        "phone_number": "+12062953142"
      },
      {
        "id": 2,
        "phone_number": "+18881231234"
      }
    ],
    '/merchants/register': [
      {
        "success": true,
        "merchant": {
          "id": "Unique Merchant ID",
          "name": "Merchant Name",
          "phone": "Merchant Phone Number",
          "email": "Merchant Email",
          "avatarUrl": "Merchant Avatar URL",
          "contact": {
            "firstName": "Contact First Name",
            "lastName": "Contact Last Name",
            "loginProvider": "Third Party Login Provider",
            "contactEmail": "Contact Email"
          }
        }
      },
      {
        "success": false,
        "message": "A merchant with the provided name, phone, or email already exists."
      }
    ]
  };

  // Simulate API call with random data from testData
  function simulateApiCall() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        var pathname = new URL(endpoint).pathname;

        if (testData[pathname]) {
          const data = testData[pathname];
          const randomIndex = Math.floor(Math.random() * data.length);
          resolve(data[randomIndex]);
        } else {
          reject(new Error(`Endpoint ${endpoint} does not exist`));
        }
      }, Math.floor(Math.random() * 200));
    });
  }

  // Perform real API call using Axios
  function performApiCall() {
    return axios({ url: endpoint, method, data: body, headers });
  }

  // Decide whether to simulate or perform real API call
  return is_simulate ? simulateApiCall() : performApiCall();
}

// // Usage example:
// RestfulAPICall('/customer', {
//   method: 'GET',
//   headers: { 'Content-Type': 'application/json' },
//   body: null
// }, true).then(response => {
//   console.log(response);
// }).catch(error => {
//   console.error(error);
// });
