import { React, useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import "./Home.css";
import ReactMarkdown from "react-markdown";

// Add this new component at the top of the file, outside the Home function
const TypewriterText = ({ text, speed = 100, onComplete }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (isComplete) return;

    const words = text.split(" ");
    let i = 0;
    const timer = setInterval(() => {
      if (i < words.length) {
        setDisplayedText((prev) => prev + (i > 0 ? " " : "") + words[i]);
        i++;
      } else {
        clearInterval(timer);
        setIsComplete(true);
        onComplete();
      }
    }, speed);

    return () => clearInterval(timer);
  }, [text, speed, isComplete, onComplete]);

  return <span>{displayedText}</span>;
};

// Update the ChatRenderer component
const ChatRenderer = ({ conversation, completedMessages, onMessageComplete, chatBodyRef }) => {
  const [lastIndex, setLastIndex] = useState(0);

  useEffect(() => {
    if (chatBodyRef && chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [lastIndex, conversation, chatBodyRef]);

  return (
    <>
      {conversation.map(
        (message, index) =>
          index <= lastIndex && (
            <div key={index} className={`message ${message.role === "assistant" ? "ai" : "user"}`}>
              {index < lastIndex ? (
                <ReactMarkdown>{message.content}</ReactMarkdown>
              ) : (
                <ReactMarkdown
                  components={{
                    p: ({ children }) => (
                      <TypewriterText
                        text={children}
                        onComplete={() => {
                          onMessageComplete(index);
                          if (index === lastIndex) {
                            if (lastIndex === conversation.length - 1) {
                              // Update lastIndex beyond the end of the conversation
                              setLastIndex(conversation.length);
                            } else {
                              // Increment lastIndex
                              setLastIndex(lastIndex + 1);
                            }
                          }
                        }}
                      />
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              )}
            </div>
          )
      )}
    </>
  );
};

export default function Home() {
  const backgroundStyle = {
    backgroundImage: "url('/marketing/background-landing-v00.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  const borderRadius = "8px";
  const borderColor = "#343a40";
  const shadowStyle = "0 4px 8px rgba(0, 0, 0, 0.1)";
  const backgroundOpacity = 0.3; // New parameter for background opacity

  const cardStyle = {
    border: `2px solid ${borderColor}`,
    boxShadow: shadowStyle,
    borderRadius: borderRadius,
    padding: "20px",
    overflow: "hidden",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`,
  };

  const imageStyle = {
    objectFit: "cover",
    width: "15%",
    borderRadius: borderRadius,
    marginLeft: "10px", // Add left margin to the left image
    marginRight: "10px", // Add right margin to the right image
  };

  const chatBodyStyle = {
    overflowY: "auto", // Add vertical scrolling if content exceeds height
    flexGrow: 1, // Allow the body to grow and fill available space
    maxHeight: "300px", // Set a maximum height for the chat body
  };

  const joyceConversation = [
    {
      role: "assistant",
      content:
        "After looking at your business metrics, customer engagement is relatively steady, " +
        "but your customer retention rate is low. (1.) " +
        "You have a strong core of regular customers, but need to convert more one-time " +
        "buyers into repeat customers. (2.) " +
        "Consider sending a series of targeted messages to dormant customers with " +
        "special offers to win them back.",
    },
    {
      role: "user",
      content: "Can you explain more about the customer retention rate?",
    },
    {
      role: "assistant",
      content:
        "Your customer retention rate is basically how well you're holding onto your\n" +
        "customers over time. A 30% retention rate means that roughly one out of three\n" +
        "customers comes back for more after their first visit.\n",
    },
    {
      role: "user",
      content: "What strategies can I implement to improve customer frequency?",
    },
    {
      role: "assistant",
      content:
        "You could try offering package deals to" +
        "encourage customers to book multiple appointments at a discount.\n",
    },
    {
      role: "user",
      content: "How does my salon's performance compare to industry standards?",
    },
    {
      role: "assistant",
      content:
        "A 30% retention rate is a good starting point, but most successful salons aim for\n" +
        "40% or higher. Don't worry, there's plenty of room for improvement with a few\n" +
        "tweaks to your strategy!\n",
    },
  ];

  const jamesConversation = [
    {
      role: "assistant",
      content: "Hey Sarah! 💖 Get 20% off your next lash fill this week. Book now!",
    },
    {
      role: "user",
      content: "Sounds great! What days do you have available?",
    },
    {
      role: "assistant",
      content:
        "Hi Sarah! 👋  We have openings for fills this week on Wednesday afternoon and Friday morning. Which day works best for you? 😊",
    },
    {
      role: "user",
      content: "Perfect, I'll take Wednesday at 2 PM. How long will it take?",
    },
    {
      role: "assistant",
      content:
        "Great! I've confirmed your appointment for Friday at 2 PM. Your classic fill should take about an hour. Is there anything else you need help with? 😊",
    },
    {
      role: "user",
      content: "Awesome, I'll see you then!",
    },
  ];

  const [showJoyceChat, setShowJoyceChat] = useState(false);
  const [showJamesChat, setShowJamesChat] = useState(false);
  const [joyceMessages, setJoyceMessages] = useState([]);
  const [jamesMessages, setJamesMessages] = useState([]);
  const [completedJoyceMessages, setCompletedJoyceMessages] = useState([]);
  const [completedJamesMessages, setCompletedJamesMessages] = useState([]);

  const joyceChatBodyRef = useRef(null);
  const jamesChatBodyRef = useRef(null);

  const portraitSize = "200px";
  const portraitStyle = {
    ...imageStyle,
    width: portraitSize,
    height: portraitSize,
  };

  useEffect(() => {
    if (showJoyceChat && joyceMessages.length < joyceConversation.length) {
      setJoyceMessages((prev) => [...prev, joyceConversation[joyceMessages.length]]);
    }
  }, [showJoyceChat, joyceMessages, completedJoyceMessages]);

  useEffect(() => {
    if (showJamesChat && jamesMessages.length < jamesConversation.length) {
      setJamesMessages((prev) => [...prev, jamesConversation[jamesMessages.length]]);
    }
  }, [showJamesChat, jamesMessages, completedJamesMessages]);

  return (
    <div className="position-relative">
      {/* Add a semi-transparent overlay */}
      <div
        className="position-absolute top-0 start-0 w-100 h-100"
        style={{
          backgroundColor: "rgba(180, 180, 180, 0.6)", // Light gray with 60% opacity
          zIndex: 1,
        }}
      ></div>

      <Container
        className="px-0 position-relative"
        style={{
          ...backgroundStyle,
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        {/* New layout with left and right panels */}
        <Row
          className="align-items-stretch"
          style={{ zIndex: 2, position: "relative", margin: "0 auto", maxWidth: "1200px" }}
        >
          {/* Left panel */}
          <Col xs={12} md={6} className="mb-4 px-3">
            <Card className="h-100" style={{ ...cardStyle, height: "auto" }}>
              <Card.Body className="d-flex flex-column justify-content-between">
                <div>
                  <h1 className="h2 text-dark mb-4">TrueUp: Your AI-Powered Marketing Team</h1>
                  <p>
                    TrueUp's AI technology automates personalized messaging, boosting engagement,
                    conversions, and customer loyalty.
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center h-100">
                  <a
                    href="https://outlook.office.com/bookwithme/user/5de8f01534884bd489fca3d8761b4881@laplaceinnovations.onmicrosoft.com/meetingtype/tvqRUxodd0ujT9qtRRgUeA2?anonymous&ep=owaSlotsCopyLink"
                    className="btn btn-primary btn-lg w-100"
                  >
                    Book a Demo
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Right panel */}
          <Col xs={12} md={6} className="mb-4 px-3">
            <Card className="h-100" style={{ ...cardStyle, height: "auto" }}>
              <Card.Body>
                <div className="d-flex flex-column flex-sm-row align-items-center mb-4">
                  <Image
                    src="/marketing/Joyce-v00.webp"
                    className="bubble-image mb-3 mb-sm-0"
                    style={portraitStyle}
                  />
                  <div className="ms-sm-3 text-center text-sm-start">
                    <h3 className="h4 mb-2">Meet Joyce</h3>
                    <p className="mb-0">
                      Your new AI marketing director. She understands your unique business
                      challenges and recommends the best strategies to meet your needs.
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row align-items-center">
                  <Image
                    src="/marketing/James-v00.webp"
                    className="bubble-image mb-3 mb-sm-0"
                    style={portraitStyle}
                  />
                  <div className="ms-sm-3 text-center text-sm-start">
                    <h3 className="h4 mb-2">Meet James</h3>
                    <p className="mb-0">
                      Your AI customer specialist. He engages with each of your customers daily,
                      gaining deep insights into their needs and preferences.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row
          className="pt-2 pt-md-5 w-100 position-relative"
          style={{ zIndex: 2, margin: "0 auto", maxWidth: "1200px" }}
        >
          {/* Chat windows */}
          <Col xs={12} className="mb-5">
            <Row>
              {/* Joyce chat window */}
              <Col xs={12} md={6} className="mb-4 px-3">
                {!showJoyceChat ? (
                  <Button onClick={() => setShowJoyceChat(true)} className="w-100 mb-3">
                    Start a chat with Joyce as a business owner
                  </Button>
                ) : (
                  <Card className="chat-window" style={cardStyle}>
                    <Card.Header
                      className="d-flex align-items-center"
                      style={{
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                      }}
                    >
                      <Image
                        src="/marketing/Joyce-v00.webp"
                        roundedCircle
                        width="40"
                        height="40"
                        className="me-2"
                      />
                      <span>Joyce</span>
                    </Card.Header>
                    <Card.Body
                      className="chat-messages"
                      style={chatBodyStyle}
                      ref={joyceChatBodyRef}
                    >
                      <ChatRenderer
                        conversation={joyceMessages}
                        completedMessages={completedJoyceMessages}
                        onMessageComplete={(index) => {
                          setCompletedJoyceMessages((prev) => [...prev, index]);
                        }}
                        chatBodyRef={joyceChatBodyRef}
                      />
                    </Card.Body>
                  </Card>
                )}
              </Col>

              {/* James chat window */}
              <Col xs={12} md={6} className="mb-4 px-3">
                {!showJamesChat ? (
                  <Button onClick={() => setShowJamesChat(true)} className="w-100 mb-3">
                    Start a chat with James as one of your customers
                  </Button>
                ) : (
                  <Card className="chat-window" style={cardStyle}>
                    <Card.Header
                      className="d-flex align-items-center"
                      style={{
                        borderTopLeftRadius: borderRadius,
                        borderTopRightRadius: borderRadius,
                      }}
                    >
                      <Image
                        src="/marketing/James-v00.webp"
                        roundedCircle
                        width="40"
                        height="40"
                        className="me-2"
                      />
                      <span>James</span>
                    </Card.Header>
                    <Card.Body
                      className="chat-messages"
                      style={chatBodyStyle}
                      ref={jamesChatBodyRef}
                    >
                      <ChatRenderer
                        conversation={jamesMessages}
                        completedMessages={completedJamesMessages}
                        onMessageComplete={(index) => {
                          setCompletedJamesMessages((prev) => [...prev, index]);
                        }}
                        chatBodyRef={jamesChatBodyRef}
                      />
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Article content */}
        <Row
          className="pt-2 pt-md-5 w-100 position-relative"
          style={{ zIndex: 2, margin: "0 auto", maxWidth: "1200px" }}
        >
          <Col xs={12} className="px-3">
            <article
              className="pt-5 text-justify"
              style={{
                ...cardStyle,
                fontSize: "1rem",
                lineHeight: "1.6",
                color: "#1a1a1a",
                height: "auto", // Override height from cardStyle
              }}
            >
              <h1 className="h2 mb-4" style={{ color: "#1a1a1a" }}>
                Transform Your Customer Interactions with TrueUp
              </h1>
              TrueUp's AI technology automates personalized messaging, boosting engagement,
              conversions, and customer loyalty.
              <p>
                Tired of ineffective marketing blasts? TrueUp analyzes customer data to craft
                tailored messages that resonate across channels, delivering the personalized
                experiences modern customers crave.
              </p>
              <h2 className="h3 mt-5 mb-3">TrueUp in Action</h2>
              <ul className="mb-4">
                <li>Reactivate dormant customers with personalized product recommendations</li>
                <li>Boost sales through smart cross-selling based on purchase history</li>
                <li>Increase conversions with targeted promotions</li>
                <li>Provide 24/7 automated support and guided solutions</li>
              </ul>
              <h2 className="h3 mt-5 mb-3">TrueUp: Tailored for Your Business</h2>
              <p>TrueUp empowers various industries:</p>
              <ul className="mb-4">
                <li>
                  <strong>Ecommerce:</strong> Skyrocket sales and retention with personalized
                  messaging
                </li>
                <li>
                  <strong>Service Businesses:</strong> Streamline appointments, gather feedback, and
                  promote offers
                </li>
                <li>
                  <strong>Consultants:</strong> Optimize client communication and lead management
                </li>
              </ul>
              <h2 className="h3 mt-5 mb-3">
                Ready to Revolutionize Your Customer Engagement with TrueUp?
              </h2>
              <p>
                Contact us now at <a href="mailto:support@gettrueup.com">support@gettrueup.com</a>{" "}
                to explore TrueUp's pricing and unlock your business's potential! Or,{" "}
                <a href="https://outlook.office.com/bookwithme/user/5de8f01534884bd489fca3d8761b4881@laplaceinnovations.onmicrosoft.com/meetingtype/tvqRUxodd0ujT9qtRRgUeA2?anonymous&ep=owaSlotsCopyLink">
                  book a demo
                </a>{" "}
                to see TrueUp in action!
              </p>
            </article>

            {/* About section */}
            <section
              className="mt-5 pt-5"
              style={{
                ...cardStyle,
                height: "auto", // Override height from cardStyle
              }}
            >
              <h2 className="h3 text-dark mb-4">About TrueUp</h2>
              <p className="text">
                Developed by Laplace Innovations Corp., TrueUp is your partner in maximizing revenue
                through a cutting-edge AI-powered messaging solution.
              </p>
              <div className="mt-3">
                <p className="mb-1">
                  <strong>Get in Touch:</strong>
                </p>
                <p className="mb-1">Email: support@gettrueup.com</p>
                <p className="mb-1">Phone: (206) 295-3142</p>
                <p className="mb-1">Address: 27 Stafford Pl, Tustin, CA 92782</p>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
