import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Form, Badge, Table, Button } from "react-bootstrap";
import ChatUI from "./ChatUI";
import "./Campaign.css";
import { createWebSocketHandler } from "./WebSocketHandler";
import { useProfileContext } from "../home/Layout";
import moment from "moment"; // Add this import for date formatting
import { REACT_APP_API_TRUEUP, REACT_APP_API_TRUEUP_WS } from "../config"; // Add this import
import axios from "axios"; // Make sure to import axios
import { useNavigate } from "react-router-dom"; // Add this import

function Campaign() {
  const { googleProfile, sessionContext } = useProfileContext();
  const [campaignName, setCampaignName] = useState("");
  const [targetAudienceDescription, setTargetAudienceDescription] = useState({
    json: "{\n  // Your JSON here\n}",
    readable: "Describe your target audience here.",
  });
  const [websocketHandler, setWebsocketHandler] = useState(null);
  const [draftMessage, setDraftMessage] = useState("Your draft message will appear here.");
  const [matchingCustomers, setMatchingCustomers] = useState([]);
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const customerListRef = useRef(null);
  const [suggestedCampaignName, setSuggestedCampaignName] = useState("");
  const [ruleSet, setRuleSet] = useState(false);
  const [messageSet, setMessageSet] = useState(false);
  const navigate = useNavigate(); // Add this hook

  const CUSTOMERS_PER_PAGE = 50;

  const createWebSocketUrl = (clientId, merchantId) => {
    return `${REACT_APP_API_TRUEUP_WS}/campaigns/ws/${clientId}?merchant_id=${merchantId}`;
  };

  const CAMPAIGN_NAME_REQUEST_DELAY = 1000; // 1 second delay

  useEffect(() => {
    if (!sessionContext || !googleProfile) return;

    const clientId = Math.floor(Math.random() * 1000000); // Generate a unique integer for the session
    const merchantId = sessionContext.merchantId;
    const wsUrl = createWebSocketUrl(clientId, merchantId);

    const handler = createWebSocketHandler(sessionContext, googleProfile, wsUrl);
    if (handler) {
      handler.connect();
      setWebsocketHandler(handler);

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "accept_rule" && message.matching_customers) {
          setMatchingCustomers(message.matching_customers);
        }
        // Add other campaign-specific message handling here if needed
      };

      handler.socket.addEventListener("message", handleMessage);

      const checkConnectionInterval = setInterval(() => {
        if (!handler.getConnectionStatus()) {
          console.log("WebSocket connection lost. Attempting to reconnect...");
          handler.connect();
        }
      }, 5000); // Check every 5 seconds

      return () => {
        clearInterval(checkConnectionInterval);
        handler.socket.removeEventListener("message", handleMessage);
        handler.close();
      };
    }
  }, [sessionContext, googleProfile]);

  const handleRuleAccepted = (ruleData) => {
    console.log("Rule accepted:", ruleData);

    setTargetAudienceDescription({
      readable: ruleData.description,
      json: JSON.stringify(ruleData, null, 2),
    });

    if (ruleData.matching_customers) {
      setMatchingCustomers(ruleData.matching_customers);
    }

    setRuleSet(true);

    if (messageSet) {
      requestCampaignName();
    }
  };

  const handleDraftMessageAccepted = (messageData) => {
    console.log("Draft message accepted:", messageData);

    setDraftMessage(messageData);

    setMessageSet(true);

    if (ruleSet) {
      requestCampaignName();
    }
  };

  useEffect(() => {
    setDisplayedCustomers(matchingCustomers.slice(0, CUSTOMERS_PER_PAGE));
  }, [matchingCustomers]);

  const loadMoreCustomers = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    const currentLength = displayedCustomers.length;
    const newCustomers = matchingCustomers.slice(currentLength, currentLength + CUSTOMERS_PER_PAGE);
    if (newCustomers.length > 0) {
      setDisplayedCustomers((prevCustomers) => [...prevCustomers, ...newCustomers]);
    }
    setIsLoading(false);
  }, [isLoading, displayedCustomers, matchingCustomers]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayedCustomers.length < matchingCustomers.length) {
          loadMoreCustomers();
        }
      },
      { threshold: 0.1 }
    );

    const listEndElement = customerListRef.current;
    if (listEndElement) {
      observer.observe(listEndElement);
    }

    return () => {
      if (listEndElement) {
        observer.unobserve(listEndElement);
      }
    };
  }, [displayedCustomers, matchingCustomers, loadMoreCustomers]);

  const renderCustomerSummary = () => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>Matching Customers Summary</Form.Label>
        <div className="customer-summary">
          {matchingCustomers.length === 0 ? (
            <p>No matching customers found. Adjust your target audience to see results.</p>
          ) : (
            <>
              <p>Total matching customers: {matchingCustomers.length}</p>
              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Last Service</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedCustomers.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer.name}</td>
                        <td>{customer.last_transaction_items[0].service}</td>
                        <td>{moment(customer.last_transaction_ts).format("MMMM D, YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {displayedCustomers.length < matchingCustomers.length && (
                <div ref={customerListRef} className="text-center mt-2">
                  {isLoading ? "Loading more..." : "Scroll to load more"}
                </div>
              )}
            </>
          )}
        </div>
      </Form.Group>
    );
  };

  const handleAcceptCampaign = async () => {
    if (matchingCustomers.length === 0) {
      alert("No matching customers found. Please adjust your target audience.");
      return;
    }

    try {
      // Save the campaign
      const saveCampaignResponse = await axios.post(
        `${REACT_APP_API_TRUEUP}campaigns/${sessionContext.merchantId}/save-campaign`,
        {
          name: campaignName,
          target_audience_json: JSON.parse(targetAudienceDescription.json),
          target_audience_description: targetAudienceDescription.readable,
          message_template: draftMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Campaign saved successfully:", saveCampaignResponse.data);

      // Navigate to campaign screen chat
      const campaignId = saveCampaignResponse.data.campaign_id;
      navigate(`/campaign/preview?campaignId=${campaignId}`);
    } catch (error) {
      console.error("Error saving campaign:", error);
      alert("Failed to save campaign. Please try again.");
    }
  };

  useEffect(() => {
    if (ruleSet && messageSet) {
      const timer = setTimeout(() => {
        requestCampaignName();
      }, CAMPAIGN_NAME_REQUEST_DELAY);

      return () => clearTimeout(timer);
    }
  }, [ruleSet, messageSet]);

  const requestCampaignName = async () => {
    console.log("Entering requestCampaignName function");
    if (!sessionContext) console.log("Missing sessionContext");
    if (!googleProfile) console.log("Missing googleProfile");
    if (!ruleSet) console.log("Rule not set");
    if (!messageSet) console.log("Message not set");

    if (!sessionContext || !googleProfile || !ruleSet || !messageSet) {
      console.log("Exiting requestCampaignName due to missing required data");
      return;
    }

    const merchantId = sessionContext.merchantId;
    const url = `${REACT_APP_API_TRUEUP}campaigns/${merchantId}/suggest-campaign-name`;
    console.log(`Request URL: ${url}`);

    try {
      console.log("Sending POST request to suggest campaign name");
      const response = await axios.post(
        url,
        {
          target_audience: targetAudienceDescription.readable,
          draft_message: draftMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Received response:", response.data);
      setSuggestedCampaignName(response.data.suggested_name);
      setCampaignName(response.data.suggested_name);
      console.log(`Set suggested campaign name to: ${response.data.suggested_name}`);
    } catch (error) {
      console.error("Error requesting campaign name:", error);
      console.error("Error details:", error.response?.data || error.message);
    }
    console.log("Exiting requestCampaignName function");
  };

  return (
    <Container
      fluid
      className="campaign-container"
      style={{ paddingTop: "100px", paddingBottom: "50px" }}
    >
      <Row className="h-100">
        <Col md={5} className="campaign-form-col d-flex flex-column">
          <div className="campaign-form-scroll flex-grow-1">
            <h2 className="mb-4">Create AI-Powered SMS Campaign</h2>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Campaign Name</Form.Label>
                <Form.Control
                  type="text"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  placeholder={suggestedCampaignName || "Enter campaign name"}
                />
                {suggestedCampaignName && (
                  <Form.Text className="text-muted">
                    Suggested name: {suggestedCampaignName}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group className="mb-3 flex-grow-1 d-flex flex-column">
                <Form.Label>Target Audience</Form.Label>
                <Form.Control
                  as="textarea"
                  className="target-audience-textarea mb-2"
                  value={targetAudienceDescription.json}
                  placeholder="JSON View"
                  style={{
                    minHeight: "150px",
                    fontFamily: "'Courier New', Courier, monospace",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    whiteSpace: "pre",
                    overflowWrap: "normal",
                    overflowX: "auto",
                  }}
                />
                <Form.Control
                  as="textarea"
                  className="target-audience-textarea"
                  value={targetAudienceDescription.readable}
                  placeholder="Readable View"
                  style={{ minHeight: "150px" }}
                />
              </Form.Group>

              {renderCustomerSummary()}

              <Form.Group className="mb-3 flex-grow-1 d-flex flex-column">
                <Form.Label>Draft Message</Form.Label>
                <Form.Control
                  as="textarea"
                  className="draft-message-textarea"
                  value={draftMessage}
                  placeholder="Your draft message will appear here"
                  readOnly
                  style={{ minHeight: "150px" }}
                />
              </Form.Group>

              {/* Add this button after the Draft Message form group */}
              <Button
                variant="primary"
                onClick={handleAcceptCampaign}
                disabled={!ruleSet || !messageSet}
                className="mt-3"
              >
                Accept Campaign
              </Button>
            </Form>
          </div>
        </Col>
        <Col md={7} className="chat-ui-col d-flex flex-column">
          <ChatUI
            onRuleAccepted={handleRuleAccepted}
            onDraftMessageAccepted={handleDraftMessageAccepted}
            websocketHandler={websocketHandler}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Campaign;
