import React, { useState } from "react";
import { Card, InputGroup, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";

import { REACT_APP_API_TRUEUP, REACT_APP_API_SMS } from "../config";
import { useProfileContext } from "../home/Layout";

const CampaignMessagePreview = (props) => {
  const { googleProfile } = useProfileContext();
  const [message, setMessage] = useState({
    merchant_id: props.merchantId,
    send_from: props.merchantPhoneNumber,
    send_to: props.customerPhone || "",
    body: props.initialDraft || "",
  });

  const handleInputChange = (e) => {
    setMessage((prevMsg) => ({ ...prevMsg, body: e.target.value }));
  };

  const handleInputBlur = () => {
    updateMessage();
  };

  const updateMessage = async () => {
    if (!message.body.trim()) return;

    const data = { draft_message: message.body };

    try {
      await axios.post(
        `${REACT_APP_API_TRUEUP}merchants/${props.merchantId}/customers/${props.customerId}/update`,
        data,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      props.onUpdateDraft(message.body);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleSendClick = async () => {
    if (!message.body.trim()) {
      alert("Message cannot be empty.");
      return;
    }

    try {
      const response = await fetch(`${REACT_APP_API_SMS}messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
        body: JSON.stringify(message),
      });

      const data = await response.json();
      if (data.success) {
        await axios.post(
          `${REACT_APP_API_TRUEUP}merchants/${props.merchantId}/customers/${props.customerId}/send_message`,
          { content: message.body },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${googleProfile.id_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        props.onMessageSent(message);
        setMessage((prevMsg) => ({ ...prevMsg, body: "" }));
      }
    } catch (error) {
      console.error("There was an error!", error);
      alert("Failed to send message.");
    }
  };

  return (
    <Card className="text-center">
      <Card.Header>
        <Card.Title>Campaign Message Preview</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.customerName} - {props.customerPhone}
        </Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <InputGroup>
          <Form.Control
            as="textarea"
            value={message.body}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder="Enter your campaign message here..."
            rows={5}
            style={{ resize: "vertical" }}
          />
        </InputGroup>
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" onClick={handleSendClick}>
          <i className="bi bi-send"></i> Send
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default CampaignMessagePreview;
