import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useProfileContext } from "../home/Layout";
import RestfulAPICall from '../utility/restfulapicall.js';     // Make sure to import the RestfulApiCall utility
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const navigate = useNavigate();
    
    const { googleProfile } = useProfileContext();

    const [merchantName, setMerchantName] = useState('');
    const [merchantPhone, setMerchantPhone] = useState('');
    const [merchantDescription, setMerchantDescription] = useState('');
    const [merchantAddress, setMerchantAddress] = useState('');
    const [merchantEmail, setMerchantEmail] = useState('');
    const [merchantAvatar, setMerchantAvatar] = useState('');

    const [phones, setPhones] = useState([]);
    const [selectedPhoneId, setSelectedPhoneId] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');

    const handleGoogleLogin = () => {
        // TODO: Implement Google login logic here
        // Use Google API to authenticate the user and retrieve their information
        // Set the retrieved first name, last name, and email in the component state
    };

    const handleSignup = async () => {
        // Validate phone numbers
        if (!merchantPhone || !contactPhone) {
            alert("Both Merchant Phone and Contact Phone are required.");
            return; // Prevent form submission
        }

        console.log(`Register.js::handleSignup: selectedPhoneId = ${selectedPhoneId}`);
        console.log(`Register.js::handleSignup: phones = ${JSON.stringify(phones)}`);
        console.log(`Register.js::handleSignup: selectedPhone = ${JSON.stringify(phones.find(phone => phone.id === selectedPhoneId))}`);
        RestfulAPICall(
            `${process.env.REACT_APP_API_SMS}sms-api/merchants/register`,
            {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "merchant_info": {
                      "name": merchantName,
                      "phone": merchantPhone,
                      "description": merchantDescription,
                      "address": merchantAddress,
                      "email": merchantEmail,
                      "avatar_url": merchantAvatar
                    },
                    "user_info": {
                      "first_name": firstName,
                      "last_name": lastName,
                      "provider": "GOOGLE",
                      "email": contactEmail,
                      "phone_number": contactPhone,
                      "sms_disabled": true
                    },
                    "phone_number": phones.find(phone => phone.id === selectedPhoneId).phone_number,
                    "login_email": googleProfile.email
                  })
            },
            false
        ).then(response => {
            console.log(`Register.js::handleSignup: response = ${JSON.stringify(response)}`);
            
            const merchantId = response.data.merchant.id;
            navigate(`/merchant/${merchantId}`);

            // if (response.data.success) {
            //     // Redirect the user to the merchant page upon successful sign-up
            //     window.location.href = '/merchant';
            // } else {
            //     // Display an error message to the user if the sign-up failed
            //     console.error('A merchant with the provided name, phone, or email already exists.');
            // }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };

    const fetchAvailablePhoneNumbers = async () => {
        try {
            const response = await RestfulAPICall(`${process.env.REACT_APP_API_SMS}sms-api/available-phone-number`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                },
                body: JSON.stringify('')
            }, false);
            
            console.log(`fetchAvailablePhoneNumbers: response = ${JSON.stringify(response.data.phone_numbers)}`);
            setPhones(response.data.phone_numbers);
            if (response.data.phone_numbers.length > 0) {
                setSelectedPhoneId(response.data.phone_numbers[0].id);
            }
        } catch (error) {
            console.error('Error fetching available phone numbers!', error);
        }
    };

    useEffect(() => {
        // If the user is not logged in, redirect them to the home page
        if (googleProfile) {
            console.log(`register.js::useEffect: ${googleProfile.id_token}`);
            RestfulAPICall(
                `${process.env.REACT_APP_API_TRUEUP}users/exists`,
                {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${googleProfile.id_token}`
                    },
                    body: JSON.stringify('')
                },
                false
            ).then(response => {
                console.log(`Register.js::useEffect: response = ${JSON.stringify(response)}`);
                if (response.data.user_id_exists === true && response.data.merchant_id_exists === true) {
                    // Redirect the user to the merchant page upon successful sign-up
                    window.location.href = '/merchant';
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });


            setFirstName(googleProfile.given_name);
            setLastName(googleProfile.family_name);

            setMerchantName(`${googleProfile.given_name?.trim() || ''} ${googleProfile.family_name?.trim() || ''}`.trim() + "'s Business");

            setMerchantEmail(googleProfile.email);
            setMerchantAvatar(googleProfile.picture);

            fetchAvailablePhoneNumbers();


        //     RestfulAPICall(`${process.env.REACT_APP_API_TRUEUP}phone-numbers/available`,
        //         {
        //             method: 'GET',
        //             headers: {
        //                 'accept': 'application/json',
        //                 'Authorization': `Bearer ${googleProfile.id_token}`,
        //             },
        //             body: JSON.stringify('')
        //         },
        //         false).then(response => {
        //             console.log(`register.js::useEffecct:Response =  ${JSON.stringify(response)}`);
        //             setPhones(response.data);
        //             // If response.data is not empty, set the first phone number as the selected phone number
        //             if (response.data.length > 0) {
        //                 setSelectedPhoneId(response.data[0].id);
        //             } else {
        //                 fetchAvailablePhoneNumbers();

        //             }
        //         }).catch(error => {
        //             console.error('There was an error!', error);
        //         });
        };


    }, []);


    return (
        <main>
            {googleProfile ? (
                <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
                    <Row>
                        <h2>Sign up an merchant account</h2>
                    </Row>

                    <Row>
                        <Row><h6>Merchant Avarta</h6></Row>
                        <Row>
                            <Col md={4}>
                                <Image src={ merchantAvatar } width={100} rounded />
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <h6>Merchant Name *</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchant">
                                    <Form.Control placeholder="Enter your business's name"
                                        value={merchantName}
                                        onChange={(e) => setMerchantName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                          </Row>
                        <Row>
                            <h6>Merchant Phone Number *</h6>
                        </Row>
                        <Row>
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchant">
                                     <PhoneInput
                                        country="US"
                                        defaultCountry="US"
                                        placeholder="Enter phone number"
                                        value={merchantPhone}
                                        onChange={setMerchantPhone}
                                        required // Make this field required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <h6>Merchant Description</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchantDescription">
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3}
                                        placeholder="Enter a description of your business"
                                        value={merchantDescription}
                                        onChange={(e) => setMerchantDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <h6>Merchant Address</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchantAddress">
                                    <Form.Control 
                                        placeholder="Enter your business's address"
                                        value={merchantAddress}
                                        onChange={(e) => setMerchantAddress(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <h6>Merchant Email</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchantEmail">
                                    <Form.Control 
                                        placeholder="Enter your business's email"
                                        value={merchantEmail}
                                        onChange={(e) => setMerchantEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>  
                    </Row>
                    <Row>
                        <Row>
                            <h6>Select an available phone number*</h6>
                            <p>The number is used to send and receive short message (SMS)</p>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                            
                                <Form.Select
                                    value={selectedPhoneId}
                                    onChange={(event)=>setSelectedPhoneId(event.target.value)}
                                    aria-label="Select an available phone number"
                                >
                                    {phones.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.phone_number}</option>
                                    })}
                                </Form.Select>
                                              
   
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <h6>Contact</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Group as={Col} controlId="formMerchant">
                                    <Form.Control placeholder='First Name'
                                        value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="2">
                                <Form.Group as={Col} controlId="formMerchant">
                                    <Form.Control placeholder='Last Name'
                                        value={lastName} onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <h6>Contact's phone number*</h6>
                    </Row>
                    <Row>
                        <Col md="5">
                            <PhoneInput
                                country="US"
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                value={contactPhone}
                                onChange={setContactPhone}
                                required // Make this field required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <h6>Contact's email</h6>
                    </Row>
                    <Row>
                        <Col md="5">
                            <Form.Group as={Col} controlId="formMerchant">
                                <Form.Control placeholder='Email'
                                    value={contactEmail} onChange={(e) => setContactEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form>
                            <Button variant='primary' onClick={handleSignup}>Submit</Button>
                        </Form>
                    </Row>
                </Container>
            ) : (
                <Alert>
                    Please sign in to create a merchant account.
                </Alert>
            )}
        </main>
    );
};

export default Register;