import React from "react";
// import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google";

import Layout from "./home/Layout";
import ErrorPage from "./error-pages";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./home/Home";
import CustomerList from "./CustomerList";
import ScreenChat from "./ScreenChat";
import CampaignScreenChat from "./campaign/CampaignPreview";
import ViewAllMessages from "./ViewAllMessages";
import Merchant from "./admin/merchant";
import UserManagement from "./admin/usermanagement";
import Subscription from "./admin/subscription";
import CustomersManagement from "./admin/customers";
import OptInForm from "./optin/OptInForm";
import Dashboard from "./dashboard/Dashboard";
import ChatUI from "./campaign/ChatUI";
import { loader as customerListLoader } from "./CustomerList";
import { loader as SMSLoader } from "./ScreenChat";
import SMSChat from "./SMS/SMSChat";
import { loader as SMSChatLoader } from "./SMS/SMSChat";
import Signup from "./signup/Signup";
import FloatingDialog from "./authentication/FloatingDialog";
import TestGoogleLogin from "./test/Google";
import Register from "./signup/register";
import SuperadminLoginAsMerchant from "./admin/superadmin-login-as-merchant";
import DraftPreview from "./draft_preview/DraftPreview";
import Campaign from "./campaign/Campaign";

import { REACT_APP_GOOGLE_CLIENT_ID } from "./config";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/customerlist",
        element: <CustomerList />,
        loader: customerListLoader,
      },
      {
        path: "/screenchat",
        element: <ScreenChat />,
        loader: SMSLoader,
      },
      {
        path: "/campaign/preview",
        element: <CampaignScreenChat />,
        loader: SMSLoader,
      },
      {
        path: "/smschat",
        element: <SMSChat />,
        loader: SMSChatLoader,
      },
      {
        path: "/viewallmessages",
        element: <ViewAllMessages />,
      },
      {
        path: "/merchant",
        element: <Merchant />,
      },
      {
        path: "/merchant/:merchantid",
        element: <Merchant />,
      },
      {
        path: "/usermanagement",
        element: <UserManagement />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      {
        path: "/customersmanagement",
        element: <CustomersManagement />,
      },

      {
        path: "/optin",
        element: <OptInForm />,
      },
      {
        path: "/optin/:merchantid",
        element: <OptInForm />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "floatingdialog",
        element: <FloatingDialog />,
      },
      {
        path: "testgoogle",
        element: <TestGoogleLogin />,
      },
      {
        path: "/superadmin-login-as-merchant",
        element: <SuperadminLoginAsMerchant />, // New component for listing merchants
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/rules",
        element: <ChatUI />,
      },
      {
        path: "draftpreview",
        element: <DraftPreview />,
      },
      {
        path: "/campaign",
        element: <Campaign />,
      },
    ],
  },
]);

export default function App() {
  return (
    <React.StrictMode>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </React.StrictMode>
  );
}
