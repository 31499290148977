/*Header.js*/

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons'; // Make sure to install react-bootstrap-icons

// import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { googleLogout, GoogleLogin } from '@react-oauth/google';
// import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
// import useToken from '../authentication/useToken';
import { jwtDecode } from 'jwt-decode';

import RestfulAPICall from '../utility/restfulapicall.js';
import { useProfileContext } from './Layout';

import axios from 'axios';
import { redirect } from 'react-router-dom';
// import { type } from '@testing-library/user-event/dist/type';

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [user, setUser] = useState([]);
  // const [user, setUser] = useState([]);
  const { googleProfile, setGoogleProfile } = useProfileContext()


  // const {token, setToken} = useToken();

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => { setUser(codeResponse); console.log('Login Success:', codeResponse) },
  //   onError: (error) => console.log('Login Failed:', error)
  // });

  const handleGoogleLogin = async (googleData) => {
    // console.log('GoogleData:', googleData.credential);
    var details= jwtDecode(googleData.credential);
    // console.log('details:', JSON.stringify(details));
    // console.log(JSON.stringify(details));
    // console.log(googleData);

    // var objUser = jwtDecode(googleData.credentials);
    // console.log('objUser:', JSON.stringify(objUser));

    // setUser(details);
    details.id_token = googleData.credential;
    setGoogleProfile(details);

    // console.log('Google login initiated');
    // console.log(googleData);

    // Call the api login if the user is not in the database
    handleClose();
    
    console.log(`Header.js::handleGoogleLogin: Bearer = ${googleData.credential}`);
    RestfulAPICall(`${process.env.REACT_APP_API_TRUEUP}users/exists`, 
      {method: 'POST',
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${googleData.credential}`,
        // 'Content-Type': 'application/json'
      },
      body: ''
      }).then(response => {
        console.log(`Header.js::handleGoogleLogin: Response = ${JSON.stringify(response)}`);
        if (response.data.merchant_id_exists === false) {
          // Using the react-router-dom to redirect to the register page
          console.log('User does not exist');
          window.location.href = '/register';
          // redirect('/register');
        }
      }).catch(error => {
        console.error('There was an error!', error);
      }); 
  };

   
  // useEffect(
  //   () => {
  //     if (user && typeof user.access_token !== 'undefined') {
  //       console.log(user.access_token);
  //       axios
  //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: 'application/json'
  //           }
  //         })
  //         .then((res) => {
  //           // setProfile(res.data);
  //           console.log('GoogleProfile:' + JSON.stringify(res.data));
  //           setGoogleProfile(res.data)
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   },
  //   [user]
  // );

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    // setProfile(null);
    setGoogleProfile(null)
  };

  return (
    <>
      <Navbar fixed="top" expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home"><Image src='/trueup.jpg' width={100} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/customerlist"><b>Send SMS</b></Nav.Link>
              <NavDropdown title="Message" id="basic-nav-dropdown">
                <NavDropdown.Item href="/customerlist">
                  <b>Send SMS</b>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/viewallmessages">
                  View all messages
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse>
          </Navbar.Collapse>
          <Navbar.Brand>
            {googleProfile ? (<>
              <NavDropdown title={googleProfile.name}>
                <NavDropdown.Item href='/merchant'>Managing Merchant</NavDropdown.Item>
                <NavDropdown.Item href='/customersmanagement'>Managing Customers</NavDropdown.Item>
                <NavDropdown.Item href='/usermanagement'>Managing Users</NavDropdown.Item>
                <NavDropdown.Item href='/subscription'>Managing Subscription</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logOut}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            </>
            ) : (
              <>
                {/* <button onClick={login}>Sign in with Google 🚀 </button> */}
                <Button variant="" className="d-flex" onClick={handleShow}>
                  <div>
                    <i className="bi bi-person"></i>
                  </div>
                  <div>
                    Sign in / Register
                  </div>
                </Button>
              </>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Sign up / Register</Modal.Title>
          <X className="ms-auto" onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Modal.Header>
        <Modal.Body>

          <p>Login with</p>
          <div>
            <div>
              <GoogleLogin
                useOneTap
                onSuccess={handleGoogleLogin}
                onError={() => console.log('Login Failed')}
              />
            </div>
          </div>
          <hr />
          <p>By continuing, you agree to our <a href="/optin/sms-terms-of-service.html" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/optin/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

        </Modal.Body>
      </Modal>
    </>
  );
}
export default Header;