import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const messageHandlers = {
  message: (message) => ({ text: message.text, sender: "ai" }),
  rule: (message) => [
    { text: message.text, sender: "ai" },
    {
      sender: "ai",
      isRule: true,
      ruleObject: message.rule,
    },
  ],
  draft_message: (message) => [
    { text: message.text, sender: "ai" },
    {
      sender: "ai",
      isDraftMessage: true,
      draftMessage: message.draft_message,
    },
  ],
  rule_and_message: (message) => [
    { text: message.text, sender: "ai" },
    {
      sender: "ai",
      isRule: true,
      ruleObject: message.rule,
    },
    {
      sender: "ai",
      isDraftMessage: true,
      draftMessage: message.draft_message,
    },
  ],
  accept_rule: (message) => {
    const result = [{ text: "Rule accepted.", sender: "ai" }];
    if (message.next_step) {
      const nextStepHandler = messageHandlers[message.next_step.type];
      if (nextStepHandler) {
        result.push(
          ...(Array.isArray(nextStepHandler(message.next_step))
            ? nextStepHandler(message.next_step)
            : [nextStepHandler(message.next_step)])
        );
      }
    }
    return result;
  },

  accept_draft_message: (message) => {
    const result = [{ text: "Draft message accepted.", sender: "ai" }];
    if (message.next_step) {
      const nextStepHandler = messageHandlers[message.next_step.type];
      if (nextStepHandler) {
        result.push(
          ...(Array.isArray(nextStepHandler(message.next_step))
            ? nextStepHandler(message.next_step)
            : [nextStepHandler(message.next_step)])
        );
      }
    }
    return result;
  },
};

function ChatInput({ onSendMessage, websocketHandler }) {
  const [inputMessage, setInputMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      onSendMessage(inputMessage);
      setInputMessage("");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3">
        <Col>
          <Form.Control
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type your message..."
          />
        </Col>
        <Col xs="auto">
          <Button type="submit" variant="primary">
            Send
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
}

function ChatMessages({ messages, onRuleAccepted, onDraftMessageAccepted, websocketHandler }) {
  const [showJsonView, setShowJsonView] = useState(false);
  const messagesEndRef = useRef(null);

  const renderRuleContent = (rule) => {
    if (showJsonView) {
      return `\`\`\`json\n${JSON.stringify({ ...rule, description: undefined }, null, 2)}\n\`\`\``;
    } else {
      return rule.description || "No description available.";
    }
  };

  const renderMessageContent = (message) => {
    if (message.isRule) {
      return renderRuleContent(message.ruleObject);
    } else if (message.isDraftMessage) {
      return message.draftMessage;
    } else {
      return message.text;
    }
  };

  const acceptRule = (rule) => {
    if (websocketHandler) {
      websocketHandler.sendMessage({ type: "accept_rule", rule: rule });
      console.log("Rule accepted:", rule);
      if (rule) {
        onRuleAccepted(rule);
      }
    }
  };

  const acceptDraftMessage = (draftMessage) => {
    if (websocketHandler) {
      websocketHandler.sendMessage({ type: "accept_draft_message", draft_message: draftMessage });
      console.log("Draft message accepted:", draftMessage);
      if (draftMessage) {
        onDraftMessageAccepted(draftMessage);
      }
    }
  };

  useEffect(() => {
    const chatWindow = document.querySelector(".chat-messages");
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      className="chat-messages"
      style={{ height: "300px", overflowY: "auto", marginBottom: "20px" }}
    >
      {messages.map((message, index) => (
        <div key={index} className={`mb-2 text-${message.sender === "user" ? "end" : "start"}`}>
          <Card
            bg={
              message.sender === "user"
                ? "primary"
                : message.isRule || message.isDraftMessage
                ? "info"
                : "light"
            }
            text={
              message.sender === "user" || message.isRule || message.isDraftMessage
                ? "white"
                : "dark"
            }
            style={{ display: "inline-block", maxWidth: "70%" }}
          >
            <Card.Body>
              {(message.isRule || message.isDraftMessage) && (
                <Card.Header as="h6" className="d-flex justify-content-between align-items-center">
                  {message.isRule ? "Parsed Rule" : "Draft Message"}
                  {message.isRule && (
                    <Form.Check
                      type="switch"
                      id={`rule-view-switch-${index}`}
                      label={showJsonView ? "JSON" : "Readable"}
                      checked={showJsonView}
                      onChange={() => setShowJsonView(!showJsonView)}
                      size="sm"
                    />
                  )}
                </Card.Header>
              )}
              <Card.Text>
                <ReactMarkdown>{renderMessageContent(message)}</ReactMarkdown>
              </Card.Text>
            </Card.Body>
            {message.isRule && (
              <Card.Footer>
                <Button variant="success" size="sm" onClick={() => acceptRule(message.ruleObject)}>
                  Apply Rule
                </Button>
              </Card.Footer>
            )}
            {message.isDraftMessage && (
              <Card.Footer>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => acceptDraftMessage(message.draftMessage)}
                >
                  Accept Draft Message
                </Button>
              </Card.Footer>
            )}
          </Card>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}

function ChatUI({ onRuleAccepted, onDraftMessageAccepted, websocketHandler }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!websocketHandler) return;

    const handleMessage = (event) => {
      const message = JSON.parse(event.data);
      const handler = messageHandlers[message.type];
      if (handler) {
        const newMessages = handler(message);
        setMessages((prevMessages) => [
          ...prevMessages,
          ...(Array.isArray(newMessages) ? newMessages : [newMessages]),
        ]);
      }
    };

    websocketHandler.socket.addEventListener("message", handleMessage);

    return () => {
      websocketHandler.socket.removeEventListener("message", handleMessage);
    };
  }, [websocketHandler]);

  const sendMessage = useCallback(
    (message) => {
      if (websocketHandler) {
        websocketHandler.sendMessage({ type: "message", message: message });
        setMessages((prevMessages) => [...prevMessages, { text: message, sender: "user" }]);
      }
    },
    [websocketHandler]
  );

  return (
    <Row className="justify-content-center">
      <Col md={12} lg={9}>
        <Card>
          <Card.Header as="h5">Chat with AI</Card.Header>
          <Card.Body>
            <ChatMessages
              messages={messages}
              onRuleAccepted={onRuleAccepted}
              onDraftMessageAccepted={onDraftMessageAccepted}
              websocketHandler={websocketHandler}
            />
          </Card.Body>
          <Card.Footer>
            <ChatInput onSendMessage={sendMessage} websocketHandler={websocketHandler} />
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}

export default ChatUI;
