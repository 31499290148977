import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Alert } from "react-bootstrap";
import { Navbar, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { customers } from './customer';

import axios from "axios";
import io from "socket.io-client";

import { businessowners } from "./businessowners.mjs";
import {
  REACT_APP_API_TRUEUP,
  REACT_APP_API_SMS,
  REACT_APP_NOTIFICATION_SERVER,
  REACT_APP_SMS_GATEWAY,
} from "./config";
import { useProfileContext } from "./home/Layout";
import SMSDialog from "./SMSDialog";

import { getSMSs } from "./SMSs";
// export const loader = async ({ params }) => {
//     return { params };
// }

export async function loader() {
  // const url = new URL(request.url);
  // const q = url.searchParams.get("q");
  // const contacts = await getContacts(q);
  // return { contacts, q };
  const Stored_SMSs = await getSMSs();
  console.log("Stored_SMSs: ", typeof Stored_SMSs, Stored_SMSs);
  return { Stored_SMSs };
}

export default function ScreenChat() {
  // const {params} = useLoaderData();
  // const { Stored_SMSs } = useLoaderData();
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");

  const { googleProfile, sessionContext } = useProfileContext();

  const [merchantId, setMerchantId] = useState(sessionContext?.merchantId);

  // const sender = googleProfile ? businessowners[googleProfile.email].phone : "";
  const [mechant_phone_number, setMerchantPhoneNumber] = useState("");

  const [customers, setCustomers] = useState([]);

  const [receivedSMS, setReceivedSMS] = useState({ From: "test", To: "test", Body: "test" });
  // const [receivedSMSs, setReceivedSMSs] = useState([]);

  const [SMSs, setSMSs] = useState([]);

  const location = useLocation();
  const navgiate = useNavigate();

  const receivedArray = JSON.parse(decodeURIComponent(location.search.split("=")[1]));
  // let filteredCustomers = customers.filter(customer => receivedArray.includes(customer.id))
  const [filteredCustomers, setFilteredCustomers] = useState(
    customers.filter((customer) => receivedArray.includes(customer.id))
  );

  // This is a walkaround to update UI when SMS is sent.
  // const [isSMSSentUpdated, setIsSMSSentUpdated] = useState(false);

  // Used to split the filteredCustomers into chunks of size = 3
  function chunkArray(array, size) {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }

  const [columnsPerRow, setColumnsPerRow] = useState(3);
  const chunkedCustomers = chunkArray(filteredCustomers, columnsPerRow);

  const updateCustomers = (customer) => {
    const newCustomers = customers.map((c) => {
      if (c.id === customer.id) {
        return customer;
      }
      return c;
    });
    // alert(JSON.stringify(newCustomers));
    setCustomers(newCustomers);
    var newFilteredCustomers = newCustomers.filter((customer) =>
      receivedArray.includes(customer.id)
    );
    setFilteredCustomers(newFilteredCustomers);
    // alert(JSON.stringify(filteredCustomers));
  };

  const refreshCustomers = () => {
    if (googleProfile) {
      axios
        .get(`${REACT_APP_API_TRUEUP}merchants/${merchantId}/customers`, {
          headers: {
            accept: "application/json",
            // 'x-api-key': REACT_APP_X_API_KEY,
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(`post ${REACT_APP_API_TRUEUP}merchants/${merchantId}/customers`);
          setCustomers(response.data);
          var newFilteredCustomers = response.data.filter((customer) =>
            receivedArray.includes(customer.id)
          );
          setFilteredCustomers(newFilteredCustomers);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }
  };
  // const updateReceivedMessage = (newMessage) => {
  //     setReceivedSMSs([...receivedSMSs, newMessage]);
  // }

  function handleBackButtonClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    // const encodeArrary = encodeURIComponent(JSON.stringify(selectedCustomers));
    // navgiate(`/customerlist?selectedCustomers=${encodeArrary}`)
    navgiate("/customerlist");
  }

  function handleSendAllButtonClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (filteredCustomers.length === 0) {
      alert("No customer selected!");
      return;
    }

    const customersWithValidMessage = filteredCustomers.filter(
      (customer) => !(!customer.draft || !customer.phone || !mechant_phone_number)
    );
    const messages = customersWithValidMessage.map((customer) => {
      return {
        from: mechant_phone_number,
        to: customer.phone,
        body: customer.draft,
        customer_id: customer.id,
      };
    });

    if (messages.length === 0) {
      alert("No valid message to send!");
      return;
    }

    console.log(JSON.stringify(messages));

    axios
      .post(`${REACT_APP_API_SMS}allmessages`, messages, {
        headers: {
          accept: "application/json",
          // 'x-api-key': REACT_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(`post ${REACT_APP_API_SMS}allmessages`);
        alert("Message sent!");

        messages.forEach((message) => {
          const data = {
            send: {
              sender_phone_number: mechant_phone_number,
            },
            merchant_customer: {
              merchant_customer_id: message.customer_id,
            },
            user: {
              email: googleProfile.email,
            },
          };

          axios
            .post(`${REACT_APP_API_TRUEUP}customers/send`, data, {
              headers: {
                accept: "application/json",
                // 'x-api-key': REACT_APP_X_API_KEY,
                Authorization: `Bearer ${googleProfile.id_token}`,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log(`POST ${REACT_APP_API_SMS}customers/send`);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              alert("Failed to update data.");
            });
        });

        refreshCustomers();
        // redirect(location.pathname);
        // return redirect(`/screenchat?${params}`)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const sendToAll = () => {
    if (filteredCustomers.length === 0) {
      alert("No customer selected!");
      return;
    }

    const customersWithValidMessage = filteredCustomers.filter(
      (customer) => !(!customer.draft || !customer.phone || !mechant_phone_number)
    );
    const messages = customersWithValidMessage.map((customer) => ({
      To: customer.phone,
      content: customer.draft,
    }));

    if (messages.length === 0) {
      alert("No valid message to send!");
      return;
    }

    // Prepare the request body
    const requestBody = messages; // The body is just the array of messages

    const sms_gateway_url = `${
      REACT_APP_SMS_GATEWAY.endsWith("/") ? REACT_APP_SMS_GATEWAY : REACT_APP_SMS_GATEWAY + "/"
    }sms/send/${merchantId}`;

    // Send the messages to the API with merchant_id in the URL
    axios
      .post(sms_gateway_url, requestBody, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(`Messages sent successfully: ${response.data}`);
        alert("Messages sent successfully!");
      })
      .catch((error) => {
        console.error("There was an error sending the messages!", error);
        const reason = error.response ? error.response.data.reason : "Unknown error";
        alert(`Failed to send messages: ${reason}`);

        // Decide whether to resend based on the reason
        if (shouldRetry(reason)) {
          // Optionally implement a retry mechanism here
          alert("Retrying to send messages...");
          sendMessages(requestBody); // Call a function to resend the messages
        }
      });
  };

  const shouldRetry = (reason) => {
    // Define logic to determine if the messages should be retried
    // For example, retry on network errors or specific error codes
    return reason === "Network Error" || reason === "Timeout";
  };

  const sendMessages = (requestBody) => {
    axios
      .post(`${REACT_APP_API_SMS}sms/send/${merchantId}`, requestBody, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(`Messages sent successfully: ${response.data}`);
        alert("Messages sent successfully!");
      })
      .catch((error) => {
        console.error("There was an error sending the messages!", error);
        const reason = error.response ? error.response.data.reason : "Unknown error";
        alert(`Failed to send messages: ${reason}`);
      });
  };

  useEffect(() => {
    if (window.innerWidth < 576) {
      setColumnsPerRow(1);
    } else if (window.innerWidth < 768) {
      setColumnsPerRow(2);
    } else {
      setColumnsPerRow(3);
    }

    if (googleProfile) {
      // setMerchantPhoneNumber(businessowners[googleProfile.email].phone);

      axios
        .get(`${REACT_APP_API_TRUEUP}merchants/${merchantId}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${googleProfile.id_token}`,
          },
        })
        .then((response) => {
          const phone = response.data.sender_phone_number.phone_number; // Extract phone number
          console.log("phone: ", phone);
          setMerchantPhoneNumber(phone); // Set the state variable
          // const data = {
          //     email: googleProfile.email,
          // };

          //            axios.post('https://www.laplace-innovations.com/api/customers', data, {
          // axios.post(`${REACT_APP_API_TRUEUP}customers`, data, {
          axios
            .get(`${REACT_APP_API_TRUEUP}merchants/${merchantId}/customers`, {
              headers: {
                accept: "application/json",
                // 'x-api-key': REACT_APP_X_API_KEY,
                "Content-Type": "application/json",
                Authorization: `Bearer ${googleProfile.id_token}`,
              },
            })
            .then((response) => {
              console.log(`get ${REACT_APP_API_TRUEUP}merchants/${merchantId}/customers`);
              setCustomers(response.data);
              var newFilteredCustomers = response.data.filter((customer) =>
                receivedArray.includes(customer.id)
              );
              setFilteredCustomers(newFilteredCustomers);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        })
        .catch((error) => {
          console.error("There was an error fetching merchant info!", error);
        });
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }

    function connectWebSocket() {
      const randomId = Math.random().toString(36).substring(2, 15); // Generate a random ID

      const websocketBaseUrl = REACT_APP_NOTIFICATION_SERVER.endsWith("/")
        ? REACT_APP_NOTIFICATION_SERVER
        : REACT_APP_NOTIFICATION_SERVER + "/";
      const websocket_url = `${websocketBaseUrl}${randomId}?merchantId=${merchantId}`; // Include randomId in the URL

      const websocket = new WebSocket(websocket_url);
      setWs(websocket);

      websocket.onopen = () => {
        console.log("WebSocket connection established");
        setConnectionStatus("Connected");
      };

      websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus("Error connecting");
        // Retry connection after a delay
        setTimeout(connectWebSocket, 2000);
      };

      websocket.onclose = () => {
        console.log("WebSocket connection closed");
        setConnectionStatus("Disconnected");
        // Retry connection after a delay
        // setTimeout(connectWebSocket, 2000);
      };

      websocket.onmessage = (event) => {
        console.log("ws message: ", event.data);
        setReceivedSMS(JSON.parse(event.data));
        let newSMS = SMSs;
        newSMS.push(JSON.parse(event.data));
        setSMSs(newSMS);
      };
    }

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      {googleProfile ? (
        <>
          {chunkedCustomers.map((chunk, index) => (
            <Row
              key={index}
              className="pt-2 pt-md-5 
                                px-4 px-xl-0 position-relative"
            >
              {chunk.map((customer) => (
                <Col
                  key={customer.id}
                  xs={12 / columnsPerRow}
                  md={12 / columnsPerRow}
                  lg={12 / columnsPerRow}
                  tag="aside"
                  className="pb-5 mb-5 pb-md-0 
                            mb-md-0 mx-auto mx-md-0"
                >
                  <SMSDialog
                    key={customer.id}
                    updateCustomers={updateCustomers}
                    refreshCustomers={refreshCustomers}
                    customer={customer}
                    // receivedSMS={receivedSMS}
                    // receivedSMSs={receivedSMSs}
                    SMSs={SMSs}
                    setSMSs={setSMSs}
                    // isSMSSentUpdated={isSMSSentUpdated}
                    // setIsSMSSentUpdated={setIsSMSSentUpdated}
                    merchantId={merchantId}
                    merchantPhoneNumber={mechant_phone_number}
                  ></SMSDialog>
                </Col>
              ))}
            </Row>
          ))}

          <Row>
            <Container>
              <Navbar fixed="bottom" bg="light">
                <Nav className="ms-auto">
                  <Button variant="secondary" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <Button variant="primary" className="ms-2" onClick={sendToAll}>
                    Send All
                  </Button>
                </Nav>
              </Navbar>
            </Container>
          </Row>
        </>
      ) : (
        <Alert color="danger" className="d-none d-lg-block">
          <strong>Please login first</strong>
        </Alert>
      )}
    </Container>
  );
}
