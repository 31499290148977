import React, { useContext, createContext } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './header';
import useGoogleProfile from '../authentication/useGoogleProfile';
import useExecutionContext from '../authentication/useExecutionContext';

const ProfileContext = createContext();

export default function Layout() {
    // const [profile, setProfile] = useState();
    const { googleProfile, setGoogleProfile } = useGoogleProfile();
    const { sessionContext, setUserSession, clearSessionContext } = useExecutionContext();

    return (
        <ProfileContext.Provider value={{ googleProfile, setGoogleProfile, sessionContext, setUserSession, clearSessionContext }} >
            <div>
                <Header/>
                <Outlet />
            </div>
        </ProfileContext.Provider>
    );
}

export function useProfileContext() {
    return useContext(ProfileContext)
}