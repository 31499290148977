import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    Alert,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
} from "react-bootstrap";

import { useProfileContext } from "./Layout";

const BANNER = "/revenue.jpg";

const LeftCard = () => {
    const { googleProfile } = useProfileContext();

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/customerlist")
    }

    return (<Fragment>
        <Alert color="danger" className="d-none d-lg-block">
            {googleProfile ? (
                    <strong>Login in as {googleProfile.name}</strong> 
                ) : (
                    <strong>Please login in first</strong>
                )
            }

        </Alert>

        <Card>
            <CardImg
                variant="top"
                width="100%"
                src={BANNER}
                alt="banner"
            />
            <CardBody>
                <CardTitle
                    className="h3 mb-2 pt-2 
                    font-weight-bold text-secondary"
                >
                    How to start to send SMS
                </CardTitle>
                <CardSubtitle
                    className="text-secondary mb-3 
                        font-weight-light text-uppercase"
                    style={{ fontSize: "0.8rem" }}
                >
                    The easiest way to boost your sales
                </CardSubtitle>
                <CardText
                    className="text-secondary mb-4"
                    style={{ fontSize: "0.75rem" }}
                >
                    Ture up is a best AI native tool to help your business
                </CardText>
                {googleProfile ? (
                    <Button
                        color="success"
                        className="font-weight-bold"
                        onClick={handleButtonClick}
                    >
                        Start Send SMS
                    </Button>
                ) : (
                    <Button
                        color="success"
                        className="font-weight-bold"
                        disabled
                    >
                        Send SMS after Login
                    </Button>
                )

                }

            </CardBody>
        </Card>
    </Fragment>
    )
};

export default LeftCard;