import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { useParams } from 'react-router-dom';

function OptInForm() {
  const { merchantid } = useParams();
  const [merchantName, setMerchantName] = useState("TrueUp");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [optInChecked, setOptInChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchMerchantName = async () => {
      if (merchantid) {
        // Set merchant name based on merchantid
        if (merchantid === '1') {
          setMerchantName("Vivi Beauty Station");
        } else if (merchantid === '2') {
          setMerchantName("Real Time Bros Heating And Air Conditioning");
        } else {
          // Default case for unknown merchantid
          setMerchantName("TrueUp");
        }
      } else {
        setMerchantName("TrueUp");
      }
    };

    fetchMerchantName();
  }, [merchantid]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you'd typically send the data to your backend for processing
    console.log("Form submitted:", name, phone, optInChecked, merchantid);
    fetch("https://api.gettrueup.com/optin", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        optin_checked: optInChecked,
        merchant_id: merchantid || 'default',
      }),
    }).then((response) => {
      console.log(response);
      setIsSubmitted(true);
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <h1>Opt in SMS</h1>
      <p>Please fill in this form to signup to learn more about {merchantName}.</p>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            country="US"
            defaultCountry="US"
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label={`I agree to receive SMS messages from ${merchantName}`}
            onChange={(e) => setOptInChecked(e.target.checked)}
          />
          <Form.Text className="text-muted">
            By submitting your name and phone number and clicking the checkbox above, you agree to
            our <a href="./sms-terms-of-service.html">Mobile Terms of Service</a> &{" "}
            <a href="./privacy-policy.html">Privacy Policy</a>. You are agreeing to receive periodic
            text messages from {merchantName} to inform you about our services. No purchase necessary.
            Message frequency varies. Standard message and data rates may apply. Reply 'STOP' to
            unsubscribe or 'HELP' for more info.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <Alert show={isSubmitted} variant="success">
          Your information has been submitted successfully.
        </Alert>
      </Form>
    </Container>
  );
}

export default OptInForm;
