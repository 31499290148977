import { useState } from "react";

export default function useExecutionContext() {
    const getSession = () => {
        const sessionString = sessionStorage.getItem("ExecutionContext");
        return sessionString ? JSON.parse(sessionString) : null;
    };

    const [sessionContext, setSessionContext] = useState(getSession);

    const saveSessionContext = (context) => {
        sessionStorage.setItem('ExecutionContext', JSON.stringify(context));
        setSessionContext(context);
    };

    const clearSessionContext = () => {
        sessionStorage.removeItem('ExecutionContext');
        setSessionContext(null);
    };

    // Example of how to set the session context
    
    const setUserSession = (user, merchantId, isSuperadminActing) => {
        const context = {
            user,
            merchantId,
            isSuperadminActing,
        };
        saveSessionContext(context);
    };

    return {
        sessionContext,
        setUserSession,
        clearSessionContext,
    };
}
