import { useState, useEffect } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Alert } from "react-bootstrap";
import { Navbar, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { customers } from './customer';

import axios from "axios";
import io from "socket.io-client";

import { businessowners } from "../businessowners.mjs";
import { REACT_APP_API_TRUEUP, REACT_APP_API_SMS, REACT_APP_NOTIFICATION_SERVER } from "../config";
import { useProfileContext } from "../home/Layout";
import SMSDialog from "./SMSDialog";

import { getSMSs, createSMS } from "./SMSs";
// import { Logger, LogLevel } from "../utility/logging";

// const logger = new Logger(LogLevel.INFO);

// export const loader = async ({ params }) => {
//     return { params };
// }

export async function loader() {
  // const url = new URL(request.url);
  // const q = url.searchParams.get("q");
  // const contacts = await getContacts(q);
  // return { contacts, q };
  const SMSs = await getSMSs();
  // logger.info(`SMSChat.js::loader SMSs: \n${JSON.stringify(SMSs)}`);
  return { SMSs };
}

export default function SMSChat() {
  // const {params} = useLoaderData();
  const { SMSs } = useLoaderData();

  const { googleProfile } = useProfileContext();
  // const sender = googleProfile ? businessowners[googleProfile.email].phone : "";
  const [mechant_phone_number, setMerchantPhoneNumber] = useState("");

  const [customers, setCustomers] = useState([]);

  const [receivedSMS, setReceivedSMS] = useState({ From: "test", To: "test", Body: "test" });
  const [receivedSMSs, setReceivedSMSs] = useState([]);

  // const [SMSs, setSMSs] = useState([]);

  const location = useLocation();
  const navgiate = useNavigate();

  const receivedArray = JSON.parse(decodeURIComponent(location.search.split("=")[1]));
  // let filteredCustomers = customers.filter(customer => receivedArray.includes(customer.id))
  const [filteredCustomers, setFilteredCustomers] = useState(
    customers.filter((customer) => receivedArray.includes(customer.id))
  );

  // This is a walkaround to update UI when SMS is sent.
  // const [isSMSSentUpdated, setIsSMSSentUpdated] = useState(false);

  // Used to split the filteredCustomers into chunks of size = 3
  function chunkArray(array, size) {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }

  const [columnsPerRow, setColumnsPerRow] = useState(3);
  const chunkedCustomers = chunkArray(filteredCustomers, columnsPerRow);

  const updateCustomers = (customer) => {
    const newCustomers = customers.map((c) => {
      if (c.id === customer.id) {
        return customer;
      }
      return c;
    });
    // alert(JSON.stringify(newCustomers));
    setCustomers(newCustomers);
    var newFilteredCustomers = newCustomers.filter((customer) =>
      receivedArray.includes(customer.id)
    );
    setFilteredCustomers(newFilteredCustomers);
    // alert(JSON.stringify(filteredCustomers));
  };

  const refreshCustomers = () => {
    if (googleProfile) {
      const data = {
        email: googleProfile.email,
      };

      axios
        .post(`${REACT_APP_API_TRUEUP}customers`, data, {
          headers: {
            accept: "application/json",
            // 'x-api-key': REACT_APP_X_API_KEY,
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(`post ${REACT_APP_API_TRUEUP}customers`);
          setCustomers(response.data);
          var newFilteredCustomers = response.data.filter((customer) =>
            receivedArray.includes(customer.id)
          );
          setFilteredCustomers(newFilteredCustomers);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }
  };
  // const updateReceivedMessage = (newMessage) => {
  //     setReceivedSMSs([...receivedSMSs, newMessage]);
  // }

  function handleBackButtonClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    // const encodeArrary = encodeURIComponent(JSON.stringify(selectedCustomers));
    // navgiate(`/customerlist?selectedCustomers=${encodeArrary}`)
    navgiate("/customerlist");
  }

  function handleSendAllButtonClick(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (filteredCustomers.length === 0) {
      alert("No customer selected!");
      return;
    }

    const customersWithValidMessage = filteredCustomers.filter(
      (customer) => !(!customer.draft || !customer.phone || !mechant_phone_number)
    );
    const messages = customersWithValidMessage.map((customer) => {
      return {
        from: mechant_phone_number,
        to: customer.phone,
        body: customer.draft,
        customer_id: customer.id,
      };
    });

    if (messages.length === 0) {
      alert("No valid message to send!");
      return;
    }

    console.log(JSON.stringify(messages));

    // axios.post('http://localhost:5000/api/allmessages', messages, {
    // axios.post('https://www.summerfang.me/api/allmessages', messages, {
    axios
      .post(`${REACT_APP_API_SMS}allmessages`, messages, {
        headers: {
          accept: "application/json",
          // 'x-api-key': REACT_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(`post ${REACT_APP_API_SMS}allmessages`);
        alert("Message sent!");

        messages.forEach((message) => {
          const data = {
            send: {
              sender_phone_number: mechant_phone_number,
            },
            merchant_customer: {
              merchant_customer_id: message.customer_id,
            },
            user: {
              email: googleProfile.email,
            },
          };

          //                    axios.post('https://www.laplace-innovations.com/api/customers/send', data, {
          axios
            .post(`${REACT_APP_API_TRUEUP}customers/send`, data, {
              headers: {
                accept: "application/json",
                // 'x-api-key': REACT_APP_X_API_KEY,
                Authorization: `Bearer ${googleProfile.id_token}`,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log(`POST ${REACT_APP_API_SMS}customers/send`);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              alert("Failed to update data.");
            });
        });

        refreshCustomers();
        // redirect(location.pathname);
        // return redirect(`/screenchat?${params}`)
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    if (window.innerWidth < 576) {
      setColumnsPerRow(1);
    } else if (window.innerWidth < 768) {
      setColumnsPerRow(2);
    } else {
      setColumnsPerRow(3);
    }

    if (googleProfile) {
      setMerchantPhoneNumber(businessowners[googleProfile.email].phone);
      const data = {
        email: googleProfile.email,
      };

      //            axios.post('https://www.laplace-innovations.com/api/customers', data, {
      axios
        .post(`${REACT_APP_API_TRUEUP}customers`, data, {
          headers: {
            accept: "application/json",
            // 'x-api-key': REACT_APP_X_API_KEY,
            Authorization: `Bearer ${googleProfile.id_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(`post ${REACT_APP_API_TRUEUP}customers`);
          setCustomers(response.data);
          var newFilteredCustomers = response.data.filter((customer) =>
            receivedArray.includes(customer.id)
          );
          setFilteredCustomers(newFilteredCustomers);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }

    // const socket = io('https://www.summerfang.me:443');
    // const socket = io('http://localhost:5000');
    const socket = io(`${REACT_APP_NOTIFICATION_SERVER}`);

    socket.on("new message", (data) => {
      console.log(data);
      setReceivedSMS(data);
      setReceivedSMSs([...receivedSMSs, data]);
      // console.log("SMSs: ", JSON.stringify(SMSs));
      // let newSMS ={ from: data.From, to: data.To, body: data.Body };
      // let newSMSs = [...SMSs, newSMS];
      // setSMSs(newSMSs);
      createSMS(data);

      console.log("SMSs: ", JSON.stringify(SMSs));
    });

    return () => {
      socket.off("message");
      socket.close();
    };
  }, [receivedSMS, googleProfile]);

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      {googleProfile ? (
        <>
          {chunkedCustomers.map((chunk, index) => (
            <Row
              key={index}
              className="pt-2 pt-md-5 
                                px-4 px-xl-0 position-relative"
            >
              {chunk.map((customer) => (
                <Col
                  key={customer.id}
                  xs={12 / columnsPerRow}
                  md={12 / columnsPerRow}
                  lg={12 / columnsPerRow}
                  tag="aside"
                  className="pb-5 mb-5 pb-md-0 
                            mb-md-0 mx-auto mx-md-0"
                >
                  <SMSDialog
                    key={customer.id}
                    updateCustomers={updateCustomers}
                    refreshCustomers={refreshCustomers}
                    customer={customer}
                    receivedSMS={receivedSMS}
                    receivedSMSs={receivedSMSs}
                    SMSs={SMSs}
                    // setSMSs={setSMSs}
                    // isSMSSentUpdated={isSMSSentUpdated}
                    // setIsSMSSentUpdated={setIsSMSSentUpdated}
                  ></SMSDialog>
                </Col>
              ))}
            </Row>
          ))}

          <Row>
            <Container>
              <Navbar fixed="bottom" bg="light">
                <Nav className="ms-auto">
                  <Button variant="secondary" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <Button variant="primary" className="ms-2" onClick={handleSendAllButtonClick}>
                    Send All
                  </Button>
                </Nav>
              </Navbar>
            </Container>
          </Row>
        </>
      ) : (
        <Alert color="danger" className="d-none d-lg-block">
          <strong>Please login first</strong>
        </Alert>
      )}
    </Container>
  );
}
