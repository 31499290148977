class WebSocketHandler {
  constructor(merchantId, idToken, websocketUrl) {
    this.merchantId = merchantId;
    this.idToken = idToken;
    this.websocketUrl = websocketUrl;
    this.socket = null;
  }

  connect() {
    this.socket = new WebSocket(this.websocketUrl);

    this.socket.onopen = () => {
      console.log("WebSocket connection established");
      this.sendAuthentication();
    };

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Received message:", message);
      // You can add additional handling here if needed
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event.code, event.reason);
    };
  }

  sendAuthentication() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          type: "authenticate",
          id_token: this.idToken,
        })
      );
    }
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log("Sending message:", message);
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open. Unable to send message.");
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }

  getConnectionStatus() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }
}

export default WebSocketHandler;

// Updated function to create a WebSocketHandler instance
export function createWebSocketHandler(sessionContext, googleProfile, websocketUrl) {
  if (!sessionContext || !googleProfile || !websocketUrl) {
    console.error("Session context, Google profile, or WebSocket URL is missing");
    return null;
  }
  return new WebSocketHandler(sessionContext.merchantId, googleProfile.id_token, websocketUrl);
}
