import React, { useState, useEffect } from 'react';
// import { useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { Container,  Row, Table } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';

import axios from 'axios';

import { REACT_APP_X_API_KEY, REACT_APP_API_TRUEUP } from '../globalvariable';

import { useProfileContext } from "../home/Layout";
// import { businessowners } from "./businessowners.mjs";


export default function CustomersManagement() {
    const [customers, setCustomers] = useState([]);
    const { googleProfile } = useProfileContext();



    useEffect(() => {
        console.log('useEffect');
        if (googleProfile) {
            const data = {
                email: googleProfile.email,
            };

            axios.post(`${REACT_APP_API_TRUEUP}customers`, data, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': REACT_APP_X_API_KEY,
                    'Authorization': `Bearer ${googleProfile.id_token}`,  
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log(response.data);
                    // alert(data);
                    setCustomers(response.data);

                })
                .catch(error => {
                    console.error('There was an error!', error);
                });

        } else {
            setCustomers([]);
        }
    }, [googleProfile])

    return (
        <main>
            <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                {googleProfile ? (
                    <>
                        <Row>
                            {/* <Table className="table" striped bordered hove> */}
                            <Table hover>

                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                            />
                                        </th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Last Visit</th>
                                        <th>Service Appointed</th>
                                        <th>Message preview</th>
                                        <th>Tag</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {customers.map((customer) => (
                                    // {searchedCustomers.map((customer) => (

                                        <tr key={customer.id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                />
                                            </td>
                                            <td>{customer.name}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.email}abc@efg.com</td>
                                            <td>{customer.lastVisit}</td>
                                            <td>{customer.serviceAppointed}</td>
                                            <td>{customer.draft}</td>
                                            <td>{customer.tag}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>

                    </>)
                    : (
                        <Alert color="danger" className="d-none d-lg-block">
                            <strong>Please login first</strong>
                        </Alert>
                    )

                }
            </Container>
        </main>
    );
}
// export default CustomerList;
